// out: ../css/main.css, outputStyle: expanded

a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h2,
h3,
h3,
h4,
h4,
h5,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
output,
p,
pre,
q,
ruby,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

ul {
    list-style: none;
}

blockquote,
q {
    quotes: none;
}

button {
    outline: none;
}

blockquote:after,
blockquote:before,
q:after,
q:before {
    content: '';
    content: none;
}

a {
    text-decoration: none;
}

body {
    background: linear-gradient(to top, #D6E8F1, #FAFAF9) no-repeat;
}

img {
    max-width: 100%;
}

html {
    box-sizing: border-box;
}

*,
*::after,
*::before {
    box-sizing: inherit;
}

a,
input,
textarea {
    outline: none;
}

a {
    &:hover {
        text-decoration: none;
    }
}
@font-face {
    font-family: "MyriadPro-Regular";
    src: url("../fonts/MyriadPro-Regular/MyriadPro-Regular.eot");
    src: local('☺'), url("../fonts/MyriadPro-Regular/MyriadPro-Regular.woff") format('woff'), url("../fonts/MyriadPro-Regular/MyriadPro-Regular.ttf") format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: "Cambria";
    src: url("../fonts/Cambria/Cambria.eot");
    src: local('☺'), url("../fonts/Cambria/Cambria.woff") format('woff'), url("../fonts/Cambria/Cambria.ttf") format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: "Verdana";
    src: url("../fonts/Verdana/Verdana.eot");
    src: local('☺'), url("../fonts/Verdana/Verdana.woff") format('woff'), url("../fonts/Verdana/Verdana.ttf") format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: "Cambria-Bold";
    src: url("../fonts/Cambria-Bold/Cambria-Bold.eot");
    src: local('☺'), url("../fonts/Cambria-Bold/Cambria-Bold.woff") format('woff'), url("../fonts/Cambria-Bold/Cambria-Bold.ttf") format('truetype');
    font-weight: normal;
    font-style: normal;
}

#magnify {
    display: none;
    position: fixed;
    max-width: 632px;
    width: 100%;
    background: white;
    height: auto;
    z-index: 9999;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    box-shadow: -7px 7px 27px rgba(0, 7, 3, 0.09);
    padding: 10px;
    @media(min-width: 768px) {
        padding: 31px 28px;
    }
}

.magnify-inner {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 414px;
    width: 100%;
    margin: 0 auto;
}

.magnify-article {
    max-width: 414px;
    width: 100%;
    margin: 18px auto 0;

    li {
        margin-bottom: 16px;
    }

    .i-link {
        color: #B4B4B4;
        font-size: 14px;
    }

    .i-alt {
        font-size: 24px;
        color: #151515;
    }
}

#magnify img {
    width: 100%;
    height: auto;
}
/* кнопка закрытия */
.modal-close {
    position: absolute;
    width: 17px;
    height: 17px;
    background: url("../img/delete.png") no-repeat center;
    border: none;
    cursor: pointer;
    transition: 0.5s;

    &:hover {
        transform: rotate(90deg);
    }
}

#close-popup {
    position: absolute;
    width: 17px;
    height: 17px;
    border: none;
    cursor: pointer;
    transition: 0.5s;
    top: 15px;
    right: 15px;
    background: url("../img/delete.png") no-repeat center;

    &:hover {
        transform: rotate(90deg);
    }
}

.fotorama__arr--next {
    background: url("../img/arrow-right.png") no-repeat center;
}

.fotorama__arr--prev {
    background: url("../img/arrow-left.png") no-repeat center;
}

.fotorama__thumb-border {
    border: none;
    background: none;
    box-shadow: -7px 5px 14px rgba(0, 7, 3, 0.09);
}
@keyframes animCloud {
    from {
        -webkit-transform: translateX(100%);
        -moz-transform: translateX(100%);
        -ms-transform: translateX(100%);
        -o-transform: translateX(100%);
        transform: translateX(100%);
    }

    to {
        -webkit-transform: translateX(-100%);
        -moz-transform: translateX(-100%);
        -ms-transform: translateX(-100%);
        -o-transform: translateX(-100%);
        transform: translateX(-100%);
    }
}

.dws-progress-bar {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
}

.progress-percentage {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 40px;
    font-family: "Verdana", sans-serif;
    font-weight: bold;
    color: #FD6B7E;
}

#preloader {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    background: linear-gradient(to top, #D6E8F1, #FAFAF9) no-repeat;
}

.position-r {
    position: relative;
    z-index: 10;
}

.box-shadow {
    box-shadow: -7px 7px 27px rgba(0, 7, 3, 0.09);
    background: white;
    position: relative;
    z-index: 10;
}

.user-action {
    padding: 20px 0;
    @media(min-width: 768px) {
        padding: 20px 0 20px 25px;
    }
    @media(min-width: 1270px) {
        padding: 50px 0 0 25px;
    }
}

.restorePass-btn {
    background: none;
    border: none;
    color: #D4D4D4;
    cursor: pointer;
    font-size: 16px;
}

.user-action_btn {
    font-family: 'Verdana';
    font-size: 13px;
    color: white;
    text-transform: uppercase;
    border-radius: 5px;
    padding: 10px 15px;
    border: none;
    cursor: pointer;
}

.overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    background: white;
    opacity: 0.9;
    top: 0;
    left: 0;
    z-index: 30;
    display: none;
}

.modal {
    padding: 20px;

    &-item {
        max-width: 406px;
        width: 100%;
        margin: 0 auto;
        padding: 25px 0;
        @media(min-width: 768px) {
            padding: 20px 0 100px;
        }
    }
}

.hover-color {
    transition: 0.5s;

    &:hover {
        color: #FF7A8B;
    }
}

.modal-links {
    text-align: center;

    button {
        background: none;
        border: none;
        cursor: pointer;
    }

    a,
    button {
        font-family: 'Cambria';
        font-size: 24px;
        text-align: center;
        color: #D4D4D4;
    }

    a {
        display: inline-block;
        color: #D4D4D4;

        &:hover {
            transition: 0.5s;
            color: #FF7A8B;
        }
    }
}

.modal-btnbox {
    text-align: center;
}

.thanks-modal {
    .modal-article {
        margin-top: 33px;

        p {
            margin-bottom: 23px;
        }
    }

    .modal-item {
        @media(min-width: 768px) {
            padding: 20px 0 55px;
        }
    }

    .modal-inner {
        &:after,
        &:before {
            content: '';
            position: absolute;
            width: 123px;
            height: 62px;
            background: url("../img/tuchka.png") no-repeat center;
            display: none;
            @media(min-width: 768px) {
                display: block;
            }
        }

        &:before {
            right: -45px;
            top: 42px;
        }

        &:after {
            left: -45px;
            bottom: 40px;
        }
    }
}

.modal-links_icon {
    position: relative;
    padding-right: 50px;

    &:after {
        content: '';
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 19px;
        height: 19px;
        background: url("../img/arrow-left.png") no-repeat center;
        opacity: 0.5;
    }
}

.modal-inp {
    margin-bottom: 10px;

    .inp {
        margin-bottom: 10px;
    }
}

.login-modal {
    background: url("../img/login-bg.jpg") no-repeat center;
    background-size: cover;

    .modal-inner {
        &:after {
            content: '';
            position: absolute;
            width: 123px;
            height: 62px;
            background: url("../img/tuchka.png") no-repeat center;
            display: none;
            left: -30px;
            top: 30px;
            @media(min-width: 768px) {
                display: block;
            }
        }
    }

    .modal-links {
        margin: 16px 0;
    }

    .modal-item {
        @media(min-width: 768px) {
            padding: 20px 0 30px;
        }
    }
}

.modal-wrongpass {
    padding-top: 13px;
    text-align: center;

    a {
        color: #D4D4D4;
    }
}

.restorepass-modal {
    background: url("../img/pas-bg.jpg") no-repeat right center;
    background-size: cover;

    .modal-btnbox {
        margin-top: 10px;
    }

    .modal-links {
        margin: 16px 0 25px;
        @media(min-width: 768px) {
            margin: 16px 0 55px;
        }
    }
}

.deleteacc-modal {
    .modal-article {
        margin: 20px 0 10px;
        @media(min-width: 768px) {
            margin: 45px 0 20px;
        }
    }

    .modal-btnbox {
        margin-top: 10px;
    }
}

.modal-inner {
    position: relative;

    .modal-close {
        right: 0;
        top: 0;
    }
}

.modal-btn {
    width: 200px;
    color: white;
    text-transform: uppercase;
    border: none;
    padding: 20px 0;
    cursor: pointer;
}

.modal-article {
    text-align: center;
    color: #C4C3C5;
    text-align: center;
}

.modal-title {
    font-family: 'Cambria';
    font-size: 24px;
    text-align: center;
    @media(min-width: 768px) {
        font-size: 36px;
    }
}

.modal-title_red {
    color: #FF6B80;
}

.modal-title_blue {
    color: #5E8EA3;
}

.modal-close {
    position: absolute;
    width: 17px;
    height: 17px;
    background: url("../img/delete.png") no-repeat center;
    border: none;
    cursor: pointer;
    transition: 0.5s;

    &:hover {
        transform: rotate(90deg);
    }
}

.breadcrumbs {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    flex-wrap: wrap;
    margin: 15px 0;
    @media(min-width: 992px) {
        margin: 0;
    }

    a {
        font-family: "Cambria";
        font-size: 14px;
        color: #5f8fa4;
        display: inline-block;
        position: relative;
        padding-right: 10px;
        margin-right: 10px;
        opacity: 0.8;
        transition: 0.5s;

        &:hover {
            opacity: 1;
        }

        &:after {
            content: '/';
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            opacity: 0.5;
        }
        @media(min-width: 992px) {
            font-size: 20px;
            padding: 15px 44px 15px 0;
            margin: 10px 28px 10px 0;

            &:after {
                content: '';
                width: 15px;
                height: 15px;
                background: url("../img/arrow-left.png") no-repeat center;
                background-size: contain;
            }
        }
    }
}

.choosetype {
    display: none;
}

.choosetype+label {
    position: relative;
    display: inline-block;
    padding-left: 35px;
    font-family: "Cambria";
    text-transform: uppercase;
    font-size: 14px;
    color: #5f8fa4;
    @media(min-width: 768px) {
        font-size: 18px;
    }

    &:after {
        content: '';
        position: absolute;
        width: 25px;
        height: 25px;
        background: url("../img/radio-bg.png") no-repeat center;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;
    }

    &:before {
        content: '';
        position: absolute;
        left: 5px;
        top: -5px;
        width: 22px;
        height: 22px;
        background: url("../img/check.png") no-repeat center;
        z-index: 10;
        display: none;
    }
}

.choosetype:checked+label:before {
    display: block;
}

.postcrossing {
    background: white url("../img/line-color.png") repeat-x top 10px center;
    box-shadow: -7px 7px 27px rgba(0, 7, 3, 0.09);
    padding: 45px 10px;
    margin-bottom: 25px;
    position: relative;
    z-index: 10;
    @media(min-width: 768px) {
        padding: 60px 44px 20px;
    }

    &-article {
        max-width: 1130px;
        width: 100%;
    }
}

.main-text {
    p {
        font-family: "MyriadPro-Regular";
        font-size: 14px;
        margin-bottom: 25px;
    }
}

.main-title {
    font-family: "Cambria";
    font-size: 25px;
    margin-bottom: 25px;
    text-transform: uppercase;
    color: #5f8fa4;
    @media(min-width: 768px) {
        font-size: 48px;
    }
}

.contarticle {
    box-shadow: -7px 7px 27px rgba(0, 7, 3, 0.09);
    padding: 35px 10px;
    margin: 28px 0;
    position: relative;
    z-index: 10;
    background: white;
    @media(min-width: 768px) {
        padding: 33px 44px 20px;
    }

    &-article {
        max-width: 1130px;
        width: 100%;
    }
}

.burger {
    position: absolute;
    right: 10px;
    top: 22px;
    width: 34px;
    height: 34px;
    cursor: pointer;
    z-index: 25;
    @media(min-width: 768px) {
        display: none;
    }

    span {
        display: block;
        width: 100%;
        background: white;
        height: 5px;
        border-radius: 5px;
        margin-bottom: 5px;
    }
}

.btn {
    font-family: "Verdana";
    padding: 21px 38px;
    text-transform: uppercase;
    color: white;
    font-size: 12px;
    display: inline-block;
    border: none;
    cursor: pointer;
}

.btn-red {
    background: rgba(254,106,128,1);

    &:hover {
        background: rgba(254,106,128,0.8);
    }
}

.btn-blue {
    background: rgba(87,154,175,1);

    &:hover {
        background: rgba(87,154,175,0.8);
    }
}

.adress-box_btn {
    margin-top: 10px;
}

.h-button span {
    font-family: "Verdana";
    color: white;
    font-size: 12px;
    display: inline-block;
    text-transform: uppercase;
    transition: 0.25s cubic-bezier(0.5, -1, 0.5, 2);
    opacity: 0;
    transform: translate(0, -20px);
}

.h-button:before {
    content: attr(data-text);
    position: absolute;
    width: 100%;
    left: 0;
    transition: 0.25s cubic-bezier(0.5, -1, 0.5, 2);
    text-transform: uppercase;
    letter-spacing: 3.5px;
    opacity: 1;
    transform: translate(0, 0px);
}

.h-button:focus:before,
.h-button:hover:before {
    opacity: 0;
    transform: translate(0, 20px);
}

.h-button:focus span,
.h-button:hover span {
    opacity: 1;
    transform: translate(0, 0);
}

.h-button:focus span:nth-child(1),
.h-button:hover span:nth-child(1) {
    transition-delay: 0.025s;
}

.h-button:focus span:nth-child(2),
.h-button:hover span:nth-child(2) {
    transition-delay: 0.05s;
}

.h-button:focus span:nth-child(3),
.h-button:hover span:nth-child(3) {
    transition-delay: 0.075s;
}

.h-button:focus span:nth-child(4),
.h-button:hover span:nth-child(4) {
    transition-delay: 0.1s;
}

.h-button:focus span:nth-child(5),
.h-button:hover span:nth-child(5) {
    transition-delay: 0.125s;
}

.h-button:focus span:nth-child(6),
.h-button:hover span:nth-child(6) {
    transition-delay: 0.15s;
}

.tooltip-btn {
    font-family: "Cambria";
    font-size: 22px;
    background: #FF7A8B;
    border-radius: 10em;
    width: 23px;
    height: 23px;
    color: white;
    text-transform: lowercase;
    cursor: pointer;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    justify-content: center;
    align-items: center;
}

.tooltip-article {
    position: absolute;
    width: 200px;
    font-family: "MyriadPro-Regular";
    font-size: 10px;
    color: #5C8CA0;
    background: rgba(255, 255, 255, 1);
    padding: 12px 10px;
    text-align: center;
    -webkit-box-shadow: 2px 4px 24px -13px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 2px 4px 24px -13px rgba(0, 0, 0, 0.75);
    box-shadow: 2px 4px 24px -13px rgba(0, 0, 0, 0.75);
    z-index: 20;
    margin-top: 14px;
    transition: 0.5s;
    visibility: hidden;
    opacity: 0;
}

.tooltip-article.active {
    visibility: visible;
    opacity: 1;
}

.tooltip-box {
    position: absolute;

    &:hover {
        .tooltip-article {
            visibility: visible;
            opacity: 1;
        }
    }

    .tooltip-article {
        a {
            color: #FF7A8B;
        }
    }
}

.tooltip-header {
    right: -6px;
    top: 4px;
    @media(min-width: 992px) {
        right: -30px;
    }
    @media(min-width: 1270px) {
        right: -36px;
    }

    .tooltip-article {
        left: -164px;
        @media(min-width: 992px) {
            left: 18px;
        }

        &_inner {
            position: relative;

            &:after {
                opacity: 1;
                top: -23px;
                right: -11px;
                @media(min-width: 992px) {
                    left: -10px;
                    top: -22px;
                    right: unset;
                }
            }
        }
    }
}

.tooltip-forum {
    left: 25px;
    bottom: 4px;

    .tooltip-article {
        left: -84px;
        @media(min-width: 768px) {
            left: 0;
        }
    }

    .tooltip-article_inner {
        span {
            color: #DF6B7C;
        }

        &:after {
            opacity: 1;
            top: -23px;
            right: 65px;
            @media(min-width: 768px) {
                right: 146px;
            }
        }
    }
}

.tooltip-forum_inner {
    left: 114px;
    @media(min-width: 768px) {
        left: 45px;
    }

    .tooltip-article_inner {
        &:after {
            right: 150px;
            @media(min-width: 768px) {
                right: 146px;
            }
        }
    }
}

.tooltip-forum,
.tooltip-header {
    .tooltip-article {
        &_inner {
            position: relative;

            &:after {
                content: "";
                position: absolute;
                border-left: 10px solid white;
                border-right: 10px solid transparent;
                border-top: 10px solid transparent;
                border-bottom: 10px solid transparent;
            }
        }
    }
}

.tooltip-kluv {
    left: 0;
    top: 4px;
    z-index: 15;

    .tooltip-btn {
        width: 42px;
        height: 42px;
        font-size: 41px;
    }
    @media(min-width: 1270px) {
        right: -36px;
    }

    .tooltip-article {
        left: 14px;
        @media(min-width: 992px) {
            left: 18px;
        }

        &_inner {
            position: relative;

            &:after {
                content: "";
                position: absolute;
                top: -23px;
                left: -11px;
                border-left: 10px solid white;
                border-right: 10px solid transparent;
                border-top: 10px solid transparent;
                border-bottom: 10px solid transparent;
                @media(min-width: 992px) {
                    left: -10px;
                    top: -22px;
                    right: unset;
                }
            }
        }
    }
}

.pulsing {
    animation: pulsing 2s infinite;
}

body {
    font-family: "Verdana";
    font-size: 14px;
    overflow-x: hidden;
    position: relative;
}

.container {
    max-width: 1434px;
    margin: 0 auto;
    width: 100%;
    padding: 0 10px;
}

.container-nav {
    @media(min-width: 768px) {
        max-width: 1434px;
        margin: 0 auto;
        width: 100%;
        padding: 0 10px;
    }
}

.cloud-wrap {
    position: relative;
}

.cloud-animate {
    position: absolute;
    -webkit-animation: animCloud 80s infinite linear;
    -moz-animation: animCloud 80s infinite linear;
    animation: animCloud 80s infinite linear;
    z-index: 0;
    display: none;
    top: 0;
    left: 0;
    @media(min-width: 1270px) {
        display: block;
    }
}

.header-cloud {
    top: 0;
    left: 0;
}

.cloud-main {
    top: -50px;
    left: 0;
}

.header {
    border-bottom: 3px dashed #ECEEF2;
    padding-bottom: 11px;
    margin-bottom: 13px;
    position: relative;
    @media(min-width: 1270px) {
        position: relative;

        &:after {
            content: "";
            position: absolute;
            left: 0;
            top: -85px;
            width: 1129px;
            height: 175px;
            background: url("../img/bg-header.png") no-repeat top center;
            display: none;
        }
    }

    &-info,
    &-top {
        @media(min-width: 768px) {
            display: -webkit-flex;
            display: -ms-flex;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px 0;
        }
    }

    &-top {
        transition: 0.5s;
        @media(max-width: 767px) {
            display: none;
        }
    }

    &-logo {
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        justify-content: center;
        position: relative;
        z-index: 10;
        @media(min-width: 768px) {
            padding-top: 19px;
        }

        &_img {
            display: block;
            width: 70px;
            @media(min-width: 992px) {
                width: 90px;
            }
            @media(min-width: 1270px) {
                width: auto;
            }
        }

        p,
        span {
            font-family: "Cambria-Bold";
            text-transform: uppercase;
            color: #579AAF;
        }

        &_article {
            position: relative;
            font-size: 20px;
            padding-top: 11px;
            @media(min-width: 992px) {
                font-size: 35px;
            }
            @media(min-width: 1270px) {
                font-size: 55px;
            }
        }

        span {
            font-size: 15px;
            @media(min-width: 882px) {
                font-size: 23px;
            }
            @media(min-width: 1270px) {
                font-size: 36px;
            }
        }
    }

    &-cont {
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        justify-content: center;
        align-items: center;
        @media(min-width: 768px) {
            margin-right: 18px;
        }
        @media(min-width: 1270px) {
            padding-top: 52px;
        }

        a {
            color: #5C8CA0;

            &:first-child {
                font-size: 30px;
                @media(min-width: 768px) {
                    font-size: 18px;
                }
                @media(min-width: 1270px) {
                    font-size: 30px;
                }
            }

            &:last-child {
                font-size: 13px;
                @media(min-width: 1270px) {
                    font-size: 16px;
                }
            }
        }

        &_item {
            &:first-child {
                display: -webkit-flex;
                display: -ms-flex;
                display: flex;
                flex-direction: column;
                margin-right: 15px;
                @media(min-width: 768px) {
                    align-items: end;
                }
            }
        }
    }

    &-basket {
        display: block;
        width: 43px;
        height: 43px;
        border-radius: 10em;
        box-shadow: -3px 3px 7px rgba(0, 7, 3, 0.1);
        background: white url("../img/cart.png") no-repeat center;
        position: relative;
        transition: 0.5s;

        .header-basket_count {
            display: inline-block;
            background: #5C8CA0;
            position: absolute;
            right: -10px;
            top: -9px;
            border-radius: 10em;
            width: 20px;
            height: 20px;
            color: white;
            font-size: 10px;
            display: -webkit-flex;
            display: -ms-flex;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &:hover {
            box-shadow: -3px 3px 7px rgba(0, 7, 3, 0);
            animation: jiggle ease-in-out 400ms infinite;
        }
    }

    &-login {
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 15px;
        @media(min-width: 768px) {
            margin-top: 0;
        }

        &_img {
            width: 43px;
            height: 43px;
            border-radius: 10em;
            background: #D0E1EC;
            display: -webkit-flex;
            display: -ms-flex;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 4px;
            box-shadow: -3px 3px 7px rgba(0, 7, 3, 0.1);
            margin-right: 12px;
            transition: 0.2s;
            @media(min-width: 1270px) {
                margin-top: 55px;
            }

            &:hover {
                border: 4px solid #FD6B7E;
            }
        }

        &_nickname {
            display: -webkit-flex;
            display: -ms-flex;
            display: flex;
            flex-direction: column;
            @media(min-width: 1270px) {
                margin-top: 55px;
            }

            a {
                color: #5C8CA0;
                font-size: 14px;

                &:first-child {
                    &:hover {
                        color: #FD6B7E;
                    }
                }

                &:last-child {
                    color: #ccdce1;

                    &:hover {
                        color: #089CB1;
                    }
                }
            }

            a.active {
                color: red;
            }

            &:last-child {
                display: -webkit-flex;
                display: -ms-flex;
                display: flex;
                flex-direction: column;
            }
        }
    }

    &-inter {
        font-family: "Cambria";
        padding-top: 25px;
        font-size: 14px;
        text-align: center;
        width: 117px;
        height: 154px;
        background: url("../img/login-bg.png") no-repeat center;
        display: inline-block;
        color: white;
        position: relative;
        border: none;
        cursor: pointer;

        span {
            position: absolute;
            top: 32px;
            left: 39px;
            text-align: center;
        }

        &:hover {
            &:after,
            &:before {
                transform: scale(1);
            }
        }

        &:after {
            content: '';
            position: absolute;
            width: 37px;
            height: 14px;
            padding: 10px 8px;
            right: 33px;
            bottom: 57px;
            background: url("../img/letter-top.png") no-repeat center;
            transition: 0.2s;
            transform: scale(0.9);
        }

        &:before {
            content: '';
            position: absolute;
            width: 41px;
            height: 24px;
            padding: 10px 8px;
            right: 31px;
            bottom: 46px;
            background: url("../img/letter-bottom.png") no-repeat center;
            transition: 1s;
            transform: scale(0.9);
        }
    }
}
@keyframes jiggle {
    0% {
        -webkit-transform: none;
        transform: none;
    }

    25% {
        -webkit-transform: rotateZ(5deg);
        transform: rotateZ(5deg);
    }

    75% {
        -webkit-transform: rotateZ(-5deg);
        transform: rotateZ(-5deg);
    }

    100% {
        -webkit-transform: none;
        transform: none;
    }
}

.login-mob {
    position: absolute;
    right: 56px;
    top: 17px;
    width: 30px;
    height: 34px;
    background: url("../img/login-mob.png") no-repeat center;
    background-size: contain;
    border: none;
    cursor: pointer;
    transition: 0.2s;
    @media(min-width: 768px) {
        visibility: hidden;
        opacity: 0;
    }
}

.container-nav {
    height: 137px;
    @media(min-width: 768px) {
        height: 230px;
    }
    @media(min-width: 992px) {
        height: 250px;
    }
}

.container-nav_sticky {
    position: relative;
    z-index: 20;
}

.logo-sticky {
    position: absolute;
    left: 0;
    top: 10px;
    visibility: hidden;
    opacity: 0;
    transition: 0.1s;

    &_img {
        display: block;
        width: 56px;
    }
    @media(max-width: 1269px) {
        display: none;
    }
}

.header-sticky_top__cont {
    max-width: 1434px;
    margin: 0 auto;
    width: 100%;
    position: relative;
}

.header-sticky_top {
    position: fixed;
    top: 0;
    left: 0;
    height: 68px;
    padding: 15px 0;
    width: 100%;
    background: #F9FAF9;
    z-index: 30;
    visibility: hidden;
    transition: 0.5s;
    opacity: 0;
    @media(max-width: 1269px) {
        display: none;
    }

    .header-sticky_top__inner {
        padding: 0 10px;
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        justify-content: flex-end;

        .header-basket {
            margin-right: 20px;
        }

        .header-login_img,
        .header-login_nickname {
            margin-top: 0;
        }
    }
}

.header-sticky_top__bg {
    position: fixed;
    top: 68px;
    height: 55px;
    width: 100%;
    left: 0;
    background: #089CB1;
    z-index: 30;
    visibility: hidden;
    transition: 0.5s;
    opacity: 0;
    @media(max-width: 1269px) {
        display: none;
    }
}

.header-sticky_top.active,
.header-sticky_top__bg.active,
.logo-sticky.active {
    visibility: visible;
    opacity: 1;
}

.main-nav.fixed {
    @media(min-width: 1270px) {
        position: fixed;
        max-width: 1444px;
        width: 100%;
        top: 68px;
    }

    .login-mob {
        @media(min-width: 1270px) {
            top: 12px;
            right: 45px;
            visibility: visible;
            opacity: 1;
        }
    }

    ul {
        @media(min-width: 1270px) {
            justify-content: center;
        }

        a {
            @media(min-width: 1270px) {
                padding: 20px 40px;
            }
            @media(min-width: 1444px) {
                padding: 20px 56px;
            }
        }
    }
}

.header-top.hidden {
    opacity: 0;
}

.stickytop-mobile {
    position: fixed;
    max-width: 1434px;
    margin: 0 auto;
    top: 0;
    left: 0;
    right: 0;
    z-index: 30;
    @media(min-width: 768px) {
        position: relative;
    }
}

.header-top_mob {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 27px 10px 4px;
    background: white;
    @media(min-width: 500px) {
        justify-content: center;
    }
    @media(min-width: 768px) {
        display: none;
    }

    .header-login {
        margin-top: 0;
        @media(min-width: 500px) {
            margin-right: 50px;
        }
    }
}

.main-nav {
    background: #089CB1;
    position: relative;
    height: 68px;
    z-index: 10;
    transition: 0.5s;

    &_logo {
        position: absolute;
        top: 3px;
        left: -10px;
        @media(min-width: 768px) {
            display: none;
        }

        .header-logo_img {
            width: 60px;
        }

        p,
        span {
            color: white;
        }
    }
    @media(min-width: 768px) {
        height: auto;
        z-index: auto;
    }

    ul {
        @media(max-width: 767px) {
            position: relative;
            top: 68px;
        }

        a {
            text-transform: uppercase;
            color: white;
            font-size: 15px;
            padding: 5px;
            display: inline-block;
            transition: 0.5s;
            position: relative;
            top: 0;
            @media(min-width: 768px) {
                font-size: 12px;
                padding: 10px 15px;

                &:hover {
                    background: #FD6B7E;
                    top: -10px;
                }
            }
            @media(min-width: 992px) {
                padding: 20px 28px;
            }
            @media(min-width: 1270px) {
                padding: 20px 40px;
            }
            @media(min-width: 1434px) {
                padding: 20px 65px;
            }
        }
        background: #089CB1;
        padding-bottom: 22px;
        text-align: center;
        display: none;
        @media(min-width: 768px) {
            padding: 0;
            display: -webkit-flex;
            display: -ms-flex;
            display: flex;
            flex-wrap: wrap;
        }
    }
}

.main-slider {
    padding-bottom: 15px;
    border-bottom: 3px dashed #EEF0F4;
    margin-top: 13px;

    .owl-dots {
        display: none;
    }

    &_hover {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        background: white;
        padding: 13px;
        font-family: "Cambria";
        text-align: center;
        display: none;

        p {
            font-size: 22px;
            color: #579AAF;
            text-transform: uppercase;
            @media(min-width: 1280px) {
                font-size: 30px;
            }
            @media(min-width: 1920px) {
                font-size: 40px;
            }
        }

        b {
            font-size: 12px;
            color: #579AAF;
            text-transform: uppercase;
            @media(min-width: 1280px) {
                font-size: 16px;
            }
            @media(min-width: 1920px) {
                font-size: 22px;
            }
        }

        .btn {
            margin-top: 25px;
        }
    }

    &_inner {
        border: 3px dashed #EEF0F4;
        padding: 25px 0;
    }

    .owl-nav.disabled {
        display: block;
    }

    .nav-left {
        background: url("../img/arrow-left.png") no-repeat center;
        width: 48px;
        height: 48px;
        border-radius: 10em;
        position: absolute;
        top: 50%;
        left: 10px;
        transform: translateY(-50%);
        box-shadow: -5px 5px 8px 1px rgba(0, 7, 3, 0.1);
        transition: 0.5s;

        &:hover {
            background: rgba(255, 255, 255, 0.9) url("../img/arrow-left.png") no-repeat center;
        }
    }

    .nav-right {
        background: url("../img/arrow-right.png") no-repeat center;
        width: 48px;
        height: 48px;
        border-radius: 10em;
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
        box-shadow: -5px 5px 8px 1px rgba(0, 7, 3, 0.1);
        transition: 0.5s;

        &:hover {
            background: rgba(255, 255, 255, 0.9) url("../img/arrow-right.png") no-repeat center;
        }
    }
}

.block-show {
    display: block;
}

.footer {
    background: #386178;
    padding: 18px 0 24px;

    &-top {
        position: relative;
    }

    &-pers {
        position: absolute;
        right: 0;
        top: -153px;
        z-index: 20;
        display: none;
        @media(min-width: 1270px) {
            display: block;
        }
    }

    &-nav {
        li {
            text-align: center;

            a {
                font-size: 14px;
                color: white;
                text-transform: uppercase;
                text-align: center;
                display: inline-block;
                position: relative;
                padding: 7px 0;
                transition: 0.5s;

                &:hover {
                    opacity: 0.5;
                }
                @media(min-width: 768px) {
                    padding: 5px 19px;
                }
                @media(min-width: 992px) {
                    padding: 5px 29px;
                }
            }

            &:not(:last-child) {
                a {
                    @media(min-width: 768px) {
                        &:after {
                            content: '';
                            position: absolute;
                            right: 0;
                            top: 0;
                            width: 1px;
                            background: white;
                            height: 31px;
                        }
                    }
                }
            }

            &:nth-child(5) {
                a {
                    @media(min-width: 768px) {
                        &:after {
                            top: 8px;
                        }
                    }
                }
            }
        }
        @media(min-width: 768px) {
            display: -webkit-flex;
            display: -ms-flex;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    &-bottom {
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;
        margin-top: 25px;
        @media(min-width: 768px) {
            flex-direction: row;
        }

        &_item {
            display: -webkit-flex;
            display: -ms-flex;
            display: flex;
            align-items: end;
            margin: 20px 0;
            @media(min-width: 768px) {
                margin: 0;
            }

            .copyright {
                margin-right: 40px;
                font-size: 13px;
                @media(min-width: 768px) {
                    margin-right: 70px;
                }
            }
        }
        @media(min-width: 992px) {
            margin-top: 70px;
        }
    }

    &-copyright {
        font-size: 12px;
        color: white;
        text-transform: uppercase;
    }

    &-social {
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        align-items: center;
        font-size: 10px;
        color: white;

        &_item {
            &:first-child {
                text-align: right;
                margin-right: 7px;
            }
        }
    }
}

.icon-slider {
    .owl-dots {
        display: none;
    }
    @media(min-width: 768px) {
        margin-top: 40px;
    }

    .owl-stage-outer {
        padding: 20px 0;
    }
    @media(min-width: 992px) {
        margin-top: 88px;
    }

    .owl-item {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 182px;
    }

    &_img {
        box-shadow: -7px 7px 15px rgba(0, 7, 3, 0.18);
        border: 3px solid #ffffff;
        border-radius: 5px;
    }

    .owl-nav,
    .owl-nav.disabled {
        display: block;
        position: absolute;
        width: 100%;
        top: 50%;
        transform: translateY(-50%);
    }

    .nav-left {
        background: url("../img/arrow-left.png") no-repeat center;
        width: 48px;
        height: 48px;
        position: absolute;
        top: -10px;
        left: 10px;
        opacity: 0.5;
        @media(min-width: 768px) {
            left: -20px;
        }
        @media(min-width: 1366px) {
            left: -25px;
        }

        &:hover {
            opacity: 1;
        }
    }

    .nav-right {
        background: url("../img/arrow-right.png") no-repeat center;
        width: 48px;
        height: 48px;
        position: absolute;
        top: -10px;
        right: 10px;
        opacity: 0.5;
        @media(min-width: 768px) {
            right: -20px;
        }
        @media(min-width: 1366px) {
            right: -25px;
        }

        &:hover {
            opacity: 1;
        }
    }

    &_link {
        color: #9FC7D1;
        font-size: 14px;
        display: inline-block;
        margin-top: 25px;
        position: relative;
        z-index: 20;
    }
}

.basket-top_item {
    @media(min-width: 768px) {
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-right: 50px;
    }
}

.basket-top {
    .news-top_inner {
        .news-top_item {
            @media(min-width: 768px) {
                &:first-child {
                    width: 10%;
                }

                &:last-child {
                    width: 90%;
                }
            }
        }
    }
}

.ether {
    margin-top: 25px;
    padding-top: 20px;
    position: relative;
    z-index: 20;
    @media(min-width: 992px) {
        margin-top: 55px;
        border-top: 3px dashed #F0F2F6;
    }
}

.ether-adress {
    box-shadow: -7px 7px 27px rgba(0, 7, 3, 0.09);
    padding: 14px;

    &_inner {
        border: 3px dashed #F0F2F6;
        padding: 25px 6px 0;
        @media(min-width: 992px) {
            padding: 25px 33px 0;
        }

        ul {
            li {
                &:not(:last-child) {
                    margin-bottom: 25px;
                }

                &:nth-child(3) {
                    margin-bottom: 0;
                    padding-left: 10px;
                }

                &:last-child {
                    text-align: right;
                }
            }
        }
    }
}

.ether-online {
    &_title {
        background: #089CB1;
        padding: 18px 27px;
        text-transform: uppercase;
        color: white;
        font-size: 12px;
        margin: 25px 0;
        @media(min-width: 992px) {
            margin: 0 0 25px;
        }
    }
}

.ether-adress_btn {
    position: relative;
    bottom: -17px;
}

.ether-slider {
    .owl-nav.disabled {
        display: block;
    }

    .owl-dots {
        display: none;
    }

    .nav-left {
        background: url("../img/arrow-left.png") no-repeat center;
        background-size: contain;
        width: 11px;
        height: 11px;
        position: absolute;
        bottom: -5px;
        right: 30px;
        transition: 0.5s;
        z-index: 20;

        &:hover {
            opacity: 0.5;
        }
    }

    .nav-right {
        background: url("../img/arrow-right.png") no-repeat center;
        background-size: contain;
        width: 11px;
        height: 11px;
        position: absolute;
        bottom: -5px;
        right: 10px;
        transition: 0.5s;
        z-index: 20;

        &:hover {
            opacity: 0.5;
        }
    }

    p {
        font-size: 12px;
        text-align: center;
        margin-bottom: 2px;
        @media(min-width: 768px) {
            font-size: 13px;
        }
        @media(min-width: 1454px) {
            font-size: 14px;
        }

        a {
            color: #3D90A5;

            &:hover {
                color: #FE6A80;
            }
        }
    }
}

.ether-inner {
    &_item {
        @media(min-width: 992px) {
            width: 50%;
        }

        &:first-child {
            background: white;
            @media(min-width: 992px) {
                width: 40%;
            }
        }

        &:last-child {
            @media(min-width: 992px) {
                width: 60%;
                padding-left: 25px;
            }
        }
    }
    @media(min-width: 992px) {
        display: flex;
        align-items: flex-start;
    }
}

.border-wrap {
    padding-bottom: 18px;
    margin-bottom: 18px;
    border-bottom: 3px dashed #E9EBEF;
}

.regestration {
    &-title {
        font-family: "Cambria-Bold";
        text-transform: uppercase;
        color: #579AAF;
        font-size: 27px;
        @media(min-width: 1270px) {
            font-size: 34px;
        }

        span {
            font-family: "Cambria";
            font-size: 28px;
            text-transform: none;
        }
    }

    &-item {
        &_title {
            margin-bottom: 25px;
            @media(min-width: 992px) {
                margin-bottom: 51px;
                display: -webkit-flex;
                display: -ms-flex;
                display: flex;
            }
        }
    }

    &-enter {
        font-family: "Cambria";
        font-size: 24px;
        color: #579AAF;
        display: inline-block;
        position: relative;
        padding-right: 39px;
        opacity: 0.4;
        margin-bottom: 25px;
        @media(min-width: 992px) {
            margin-bottom: 0;
            margin-right: 25px;
        }
        @media(min-width: 1270px) {
            margin-right: 55px;
        }
        @media(min-width: 1443px) {
            margin-right: 85px;
        }

        &:after {
            content: '';
            position: absolute;
            right: 0;
            top: 7px;
            width: 19px;
            height: 19px;
            background: url("../img/arrow-left.png") no-repeat center;
            opacity: 0.4;
        }

        &:hover {
            opacity: 1;

            &:after {
                opacity: 1;
            }
        }
    }

    &-bottom,
    &-top {
        padding: 25px 10px;
        @media(min-width: 1270px) {
            padding: 34px 55px;
        }
    }

    &-top {
        &_inner {
            @media(min-width: 992px) {
                display: -webkit-flex;
                display: -ms-flex;
                display: flex;
                justify-content: space-between;
            }

            .regestration-top_item {
                &:first-child {
                    @media(min-width: 992px) {
                        width: 60%;
                        margin-right: 15px;
                    }
                }

                &:last-child {
                    .inp {
                        margin-bottom: 23px;
                    }
                    @media(min-width: 992px) {
                        width: 40%;
                    }
                }
            }
        }
    }
}

.pass-title {
    width: 100%;
    text-align: center;
    color: white;
    text-transform: uppercase;
    font-size: 12px;
    padding: 17px 0;
    background: #579AAF;
    margin-bottom: 23px;
}

input::-webkit-input-placeholder {
    color: #B4B4B4;
}

input:-ms-input-placeholder {
    color: #B4B4B4;
}

input::-ms-input-placeholder {
    color: #B4B4B4;
}

input::placeholder {
    color: #B4B4B4;
}

.inp {
    width: 100%;
    height: 48px;
    background: #F8F8F7;
    border: none;
    padding: 0 5px;
    font-size: 12px;
    color: black;
    @media(min-width: 768px) {
        padding: 0 20px;
        font-size: 14px;
    }

    &:focus {
        border: 2px dashed #6E8DA1;
    }
}

.adress-box {
    li {
        margin-bottom: 10px;
        @media(min-width: 768px) {
            display: -webkit-flex;
            display: -ms-flex;
            display: flex;
        }

        &:last-child {
            margin-top: 45px;
            @media(min-width: 992px) {
                margin-top: 78px;
            }
        }
    }

    label {
        font-size: 14px;
        @media(min-width: 768px) {
            width: 40%;
            padding-top: 12px;
        }
    }

    &_inp {
        max-width: 440px;
        width: 100%;
        padding-right: 25px;
        @media(min-width: 768px) {
            width: 60%;
        }
    }

    &_inner {
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;

        .adress-box_inp {
            width: 33%;
            padding-right: 0;

            &:not(:last-child) {
                margin-right: 9px;
            }
        }
    }

    &_arrow {
        position: relative;

        &:after {
            content: '';
            position: absolute;
            right: 0;
            top: 18px;
            width: 11px;
            height: 11px;
            background: url("../img/arrow-down.png") no-repeat center;
            opacity: 0.5;
        }
    }
}

.inp-box {
    margin-bottom: 23px;

    .inp-label {
        font-size: 14px;
    }
    @media(min-width: 768px) {
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;

        .inp-label {
            width: 40%;
            padding-top: 13px;
        }

        .inp-box_inner {
            width: 60%;
        }
    }
}

.privacy-label {
    margin-top: 23px;
    font-size: 12px;
    @media(min-width: 1270px) {
        font-size: 14px;
    }
}

.mess-check {
    display: none;
}

.mess-check+label {
    position: relative;
    display: inline-block;
    padding-left: 43px;
    cursor: pointer;

    &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 25px;
        height: 25px;
        border-radius: 10em;
        border: 1px solid #E7E7E7;
        z-index: 1;
    }

    &:before {
        content: '';
        position: absolute;
        left: 4px;
        top: 0;
        width: 22px;
        height: 22px;
        background: url("../img/check.png") no-repeat center;
        z-index: 10;
        display: none;
    }
}

.mess-check:checked + label:before {
    display: block;
}

.regestration-bottom {
    background: white url("../img/cabinet-bg.png") no-repeat right bottom;

    &_title {
        font-family: 'Cambria';
        font-size: 27px;
        color: #589bb0;
        margin-bottom: 25px;
        @media(min-width: 992px) {
            margin-bottom: 55px;
        }
    }

    &_inner {
        @media(min-width: 1270px) {
            display: -webkit-flex;
            display: -ms-flex;
            display: flex;
            justify-content: space-between;
        }

        .regestration-bottom_item {
            &:first-child {
                @media(min-width: 1270px) {
                    width: 60%;
                    margin-right: 56px;
                }
            }

            &:last-child {
                display: -webkit-flex;
                display: -ms-flex;
                display: flex;
                flex-direction: column;
                align-items: center;
                @media(min-width: 1270px) {
                    width: 33%;
                }
            }
        }
    }
}

.mayor-kluv {
    margin-bottom: 25px;
    position: relative;
    display: inline-block;
    animation: moving 2s infinite linear;

    &_sircle {
        position: absolute;
        left: 50px;
        top: 13px;
        width: 300px;
        height: 300px;
        background: #E0EFF4;
        border-radius: 10em;
        z-index: 1;
        -webkit-animation: pulsing 2s infinite;
        animation: pulsing 2s infinite;
    }

    &_img {
        position: relative;
        z-index: 10;
    }
    @media(min-width: 992px) {
        margin-bottom: 55px;
    }
}
@-webkit-keyframes moving {
    0% {
        top: 20px;
    }

    75% {
        top: 0;
    }

    100% {
        top: 20px;
    }
}
@-webkit-keyframes pulsing {
    0% {
        -webkit-transform: scale(0.5, 0.5);
        transform: scale(0.5, 0.5);
    }

    50% {
        -webkit-transform: scale(1.0, 1.0);
        transform: scale(1.0, 1.0);
    }

    100% {
        -webkit-transform: scale(0.5, 0.5);
        transform: scale(0.5, 0.5);
    }
}
@keyframes pulsing {
    0% {
        -webkit-transform: scale(0.5, 0.5);
        transform: scale(0.5, 0.5);
    }

    50% {
        -webkit-transform: scale(1.0, 1.0);
        transform: scale(1.0, 1.0);
    }

    100% {
        -webkit-transform: scale(0.5, 0.5);
        transform: scale(0.5, 0.5);
    }
}

.mayor-kluv_slider {
    max-width: 424px;
    width: 100%;
    box-shadow: -7px 7px 27px rgba(0, 7, 3, 0.09);

    .owl-dots {
        display: none;
    }

    .owl-nav {
        position: absolute;
        right: 0;
    }

    .nav-left {
        background: url("../img/arrow-left.png") no-repeat center;
        background-size: contain;
        width: 11px;
        height: 11px;
        position: absolute;
        bottom: -5px;
        right: 30px;
        transition: 0.5s;
        z-index: 20;
        opacity: 0.5;

        &:hover {
            opacity: 1;
        }
    }

    .nav-right {
        background: url("../img/arrow-right.png") no-repeat center;
        background-size: contain;
        width: 11px;
        height: 11px;
        position: absolute;
        bottom: -5px;
        right: 10px;
        transition: 0.5s;
        z-index: 20;
        opacity: 0.5;

        &:hover {
            opacity: 1;
        }
    }

    .item {
        font-family: "MyriadPro-Regular";
        padding: 10px;
        background: white;
        @media(min-width: 768px) {
            padding: 25px;
            font-size: 14px;
        }
    }
}

.privacy-check {
    text-align: center;
    margin: 25px 0;
    @media(min-width: 992px) {
        margin: 90px 0 60px;
    }

    label {
        font-size: 14px;

        a {
            color: #5f8fa4;
        }
    }

    &_inp {
        display: none;
    }

    &_inp+label {
        position: relative;
        padding-left: 40px;
        cursor: pointer;

        &:before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 20px;
            height: 20px;
            background: #CDDEE9;
        }

        &:after {
            content: "";
            position: absolute;
            left: 2px;
            top: -5px;
            width: 22px;
            height: 22px;
            background: url("../img/check.png") no-repeat center;
            display: none;
        }
    }

    &_inp:checked + label:after {
        display: block;
    }
}

.cabinet-top {
    @media(min-width: 992px) {
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
    }

    &_item {
        &:first-child {
            background: #63A1B5;
            padding-top: 39px;
            display: -webkit-flex;
            display: -ms-flex;
            display: flex;
            flex-direction: column;
            align-items: center;
            @media(min-width: 992px) {
                width: 30%;
            }
        }

        &:last-child {
            padding: 20px;
            @media(min-width: 992px) {
                width: 70%;
            }
            @media(min-width: 1443px) {
                padding: 40px 143px 0 72px;
            }
        }
    }

    &_btn {
        li {
            text-align: center;

            &:not(:last-child) {
                margin-bottom: 11px;
            }
        }
    }

    &_title {
        @media(min-width: 768px) {
            display: -webkit-flex;
            display: -ms-flex;
            display: flex;
            justify-content: space-between;
            margin-bottom: 17px;
        }

        .main-title {
            text-transform: none;
            font-size: 36px;
            margin-bottom: 21px;
        }
    }
}

.cabinet-visit_date {
    font-size: 14px;
    color: #68a5b7;
}

.cabinet-ava {
    position: relative;
    width: 145px;
    height: 147px;
    overflow: hidden;
    border-radius: 10em;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
}

.cabinet-ava_wrap {
    position: relative;
    margin-bottom: 24px;
    cursor: pointer;

    &:after {
        content: '';
        top: -7px;
        left: -8px;
        position: absolute;
        border-radius: 10em;
        width: 161px;
        height: 160px;
        border: 3px dashed #7EB1C1;
        transition: 2s;
    }

    &:hover {
        &:after {
            transform: rotate(-180deg);
        }
    }
}

.ava-file {
    display: none;
}

.ava-file+label {
    font-size: 14px;
    color: white;
    cursor: pointer;
}

.delete-ava {
    font-size: 14px;
    color: white;
    opacity: 0.5;

    &:hover {
        opacity: 1;
    }
}

.regpostcard-btn,
.sendpostcard-btn {
    width: 155px;
    padding: 20px 0;
}

.btn-opacity {
    &:hover {
        opacity: 0.7;
    }
}

.regpostcard-btn {
    background: #77B2C4;
    padding: 14px 0;

    &:hover {
        background: #FE6A80;
    }
}

.cabinet-top_basket {
    position: relative;
    display: inline-block;
    padding-left: 47px;
    color: #5f8fa4;
    font-family: 'Cambria';
    font-size: 24px;
    margin: 20px 0;
    @media(min-width: 768px) {
        margin: 11px 0 0;
    }

    &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 3px;
        width: 26px;
        height: 22px;
        background: url("../img/cart.png") no-repeat center;
    }

    &:hover:after {
        animation: jiggle ease-in-out 400ms infinite;
    }
}

.delete-account {
    display: inline-block;
    margin-top: 41px;
    margin-bottom: 25px;
    font-size: 14px;
    color: #68a5b7;
    font-size: 14px;
    background: none;
    border: none;
    cursor: pointer;

    &:hover {
        color: red;
    }
}

.cabinet-top_textarea {
    font-family: 'Verdana';
    font-size: 14px;
    width: 100%;
    background: #F8F8F7;
    padding: 18px;
    height: 191px;
    resize: none;
    border: none;
}

.cabinet-top_person {
    @media(min-width: 1270px) {
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
    }
}

.cabinet-top_person_left {
    li {
        &:not(:last-child) {
            margin-bottom: 23px;
        }
    }
    @media(min-width: 1270px) {
        width: 50%;
        margin-right: 16px;
    }
}

.cabinet-top_person_right {
    @media(min-width: 1270px) {
        width: 50%;
    }

    li {
        &:last-child {
            text-align: right;
        }
    }
}

.cabinet-top_addphoto {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    margin-bottom: 25px;
    margin-top: 7px;

    li {
        &:not(:last-child) {
            height: 50px;
            position: relative;

            .cabinet-img_min {
                width: 28px;
                height: 28px;
                background: #C3C3C3;
                margin-right: 7px;
                padding-bottom: 25px;
                overflow: hidden;
                position: relative;

                &:after {
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    left: 0;
                    top: 0;
                    background: #FD6A7E;
                    display: none;
                }
            }

            .delete-photo {
                position: absolute;
                border: none;
                width: 12px;
                height: 12px;
                left: 5px;
                bottom: 6px;
                background: url("../img/close.png") no-repeat center;
                display: none;
                cursor: pointer;
            }

            &:hover {
                .delete-photo {
                    display: block;
                }

                .cabinet-img_min:after {
                    display: block;
                }
            }
        }
    }
}

.min-photo_add {
    display: none;
}

.min-photo_add+label {
    color: #589bb0;
    font-size: 11px;
    padding-top: 8px;
    display: inline-block;
    padding-left: 10px;
    cursor: pointer;
}

.cabinet-middle {
    padding: 33px 10px;
    margin-bottom: 38px;
    @media(min-width: 1270px) {
        padding: 33px 67px;
        background: white url("../img/cabinet-bg.png") no-repeat right bottom;
    }

    &_title {
        font-size: 22px;
        position: relative;
        padding-right: 50px;
        display: inline-block;
        text-transform: none;
        @media(min-width: 1270px) {
            font-size: 24px;
        }

        &:after {
            content: '';
            position: absolute;
            right: 0;
            top: 6px;
            width: 19px;
            height: 19px;
            background: url("../img/arrow-down-big.png") no-repeat center;
        }
    }

    &_changepass {
        li {
            margin-bottom: 9px;
        }
        margin-bottom: 25px;
        @media(min-width: 992px) {
            margin-bottom: 83px;
        }
    }

    &_privacy {
        font-size: 14px;
        margin-top: 25px;
        text-align: center;
        @media(min-width: 992px) {
            text-align: left;
            margin-top: 120px;
        }

        a {
            color: #5f8fa4;
        }
    }

    &_inner {
        &__item {
            margin-bottom: 25px;

            &:first-child {
                @media(min-width: 992px) {
                    margin-right: 20px;
                }
                @media(min-width: 1270px) {
                    .adress-box {
                        padding-left: 100px;
                    }
                }
            }

            &:last-child {
                max-width: 348px;
                width: 100%;
                margin: 0 auto;
                @media(min-width: 1443px) {
                    margin: unset 122px unset unset;
                }
            }
        }
        @media(min-width: 992px) {
            display: -webkit-flex;
            display: -ms-flex;
            display: flex;
            justify-content: space-between;
        }
    }
}

.changepass_btn {
    width: 100%;
    background: #579AAF;
    padding: 18px 0;
}

.social-imgbox {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
}

.choose-social {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    align-items: center;

    li {
        margin-right: 3px;
        cursor: pointer;
    }
}

.choose-social_title {
    font-size: 14px;
    color: #a0a0a0;
    margin: 27px 0 14px;
}

.cabinet-middle_social__img {
    width: 54px;

    img {
        display: block;
    }
}

.cabinet-middle_social__inp {
    width: 100%;
}

.postcard-info {
    background: white url("../img/line-color.png") repeat-x top 14px center;
    padding: 40px 0 27px;
    margin-bottom: 25px;
    @media(min-width: 992px) {
        padding: 74px 0 27px;
    }

    &_top {
        padding: 0 20px;

        .main-title {
            font-size: 24px;
            text-transform: none;
            @media(min-width: 992px) {
                margin-bottom: 0;
            }
            @media(min-width: 1270px) {
                font-size: 36px;
            }
        }
        @media(min-width: 992px) {
            display: -webkit-flex;
            display: -ms-flex;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        @media(min-width: 1270px) {
            padding: 0 60px;
        }
    }

    &_tabs {
        @media(min-width: 768px) {
            display: -webkit-flex;
            display: -ms-flex;
            display: flex;
            align-items: center;
        }

        a {
            font-family: 'Cambria';
            display: inline-block;
            padding: 10px;
            font-size: 24px;
            color: #6f8ea2;
            border: 2px dashed transparent;
            @media(min-width: 768px) {
                margin-right: 10px;
            }
            @media(min-width: 1270px) {
                padding: 27px;
            }

            &:hover {
                color: #DF6A7C;
            }
        }

        a.active {
            border: 2px dashed #6E8DA1;
        }
    }
}

.postcard-table {
    overflow-x: auto;

    table {
        border-collapse: collapse;
        border-spacing: 0;
        width: 1414px;

        tr {
            th {
                font-size: 14px;
                vertical-align: middle;

                a {
                    color: #5f8fa4;
                }
            }

            &:first-child {
                th {
                    padding: 22px 0;
                }
            }

            &:hover {
                background: #F4F9FA;

                .postcard-table_arrow {
                    opacity: 1;
                }
            }
        }

        .postcard-table_main {
            border-bottom: 2px dashed #F1F2F6;

            th {
                padding: 12px 0;
                transition: 0.5s;
            }

            &:hover {
                .status-tooltip {
                    display: block;
                }

                .postcard-table_img {
                    width: 97px;
                }

                .delete-table {
                    display: block;
                }
            }
        }
    }

    &_img {
        display: inline-block;
        width: 51px;
        margin: 0 auto;
        box-shadow: -7px 7px 27px rgba(0, 7, 3, 0.18);
        border: 3px solid #ffffff;
        transition: 0.5s;

        img {
            display: block;
        }
    }
}

.copyright {
    color: white;

    i {
        opacity: 0.5;
        transition: 0.5s;
    }

    span {
        opacity: 1;
    }

    &:hover {
        i {
            opacity: 1;
        }
    }
}

.status-tooltip {
    position: absolute;
    top: -5px;
    left: 47%;
    display: none;
    z-index: 10;

    &_sircle {
        width: 11px;
        height: 11px;
        background: #6E8DA1;
        border-radius: 10em;
    }

    &_article {
        position: relative;

        &:after {
            content: "";
            position: absolute;
            top: -24px;
            right: -5px;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-top: 10px solid white;
        }
    }

    &_inner {
        position: absolute;
        top: -78px;
        left: -100px;
        width: 220px;
        background: white;
        box-shadow: -7px 7px 27px rgba(0, 7, 3, 0.2);
        padding: 10px 24px;
        color: #476d86;
        font-size: 14px;
    }
}

.postcard-table_imgbox {
    width: 258px;
    padding-left: 90px;
    position: relative;

    .delete-table {
        top: 50%;
        transform: translateY(-50%);
    }
}

.postcard-table_item {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    justify-content: center;
    align-items: center;

    .postcard-table_img {
        margin: 0;
    }

    .send-date {
        margin: 0 30px;
    }
}

.postcard-table_arrow {
    width: 694px;
    transition: 0.5s;
    opacity: 0.5;

    .status-arrow_r {
        border-bottom: 2px dashed #6E8DA1;
        position: relative;

        &:after {
            content: '';
            position: absolute;
            right: -3px;
            top: -8px;
            width: 10px;
            height: 17px;
            background: url("../img/line-arrow-r.png") no-repeat center;
        }
    }

    .status-arrow_l {
        position: relative;
        z-index: 0;

        &:after {
            content: '';
            position: absolute;
            left: -3px;
            top: -8px;
            width: 100%;
            height: 17px;
            background: url("../img/arrow-red.png") no-repeat center;
        }
    }
}

.send-number {
    position: relative;
    display: inline-block;
}

.delete-table {
    position: absolute;
    left: -40px;
    top: 3px;
    width: 17px;
    height: 17px;
    background: url("../img/delete.png") no-repeat center;
    cursor: pointer;
    display: none;
    transition: 0.5s;

    &:hover {
        transform: rotate(90deg);
    }
}

.postcard-pagination {
    margin: 35px 0 25px;
    text-align: center;

    .page-num {
        display: inline-block;
        width: 45px;
        height: 45px;
        line-height: 45px;
        font-size: 16px;
        margin-right: 5px;
        text-align: center;
        color: #5F8FA4;
    }

    .current {
        background: #5F8FA4;
        color: white;
    }
}

.forum-top {
    margin-bottom: 25px;
    @media(min-width: 768px) {
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
    }
    @media(min-width: 992px) {
        margin-bottom: 34px;
    }

    .main-title {
        font-size: 36px;
        text-transform: none;
        @media(min-width: 768px) {
            margin-bottom: 0;
        }
    }

    &_item {
        &:first-child {
            padding: 10px;
            margin-bottom: 11px;
            max-width: 1154px;
            width: 100%;
            @media(min-width: 768px) {
                display: -webkit-flex;
                display: -ms-flex;
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-right: 16px;
                padding: 27px 10px;
            }
            @media(min-width: 992px) {
                padding: 36px 10px 43px;
            }
            @media(min-width: 1270px) {
                padding: 36px 55px 43px;
            }
        }

        &:last-child {
            @media(min-width: 768px) {
                width: 30%;
            }
            @media(min-width: 1270px) {
                width: 21%;
            }
        }
    }

    &_date {
        p {
            font-size: 14px;
            color: #b7b7b7;
            margin-bottom: 15px;
        }

        &__item {
            display: -webkit-flex;
            display: -ms-flex;
            display: flex;

            li {
                width: 123px;

                .inp {
                    padding: 0 4px;
                }

                &:first-child {
                    margin-right: 23px;
                    position: relative;

                    &:after {
                        content: '';
                        position: absolute;
                        right: -14px;
                        top: 50%;
                        transform: translateY(-50%);
                        width: 5px;
                        height: 1px;
                        background: black;
                    }
                }
            }
        }
        @media(min-width: 992px) {
            display: -webkit-flex;
            display: -ms-flex;
            display: flex;
            align-items: center;
            padding-top: 8px;

            p {
                margin-bottom: 0;
                margin-right: 15px;
            }
        }
    }
}

.notread-themes_btn,
.read-themes_btn {
    display: block;
    box-shadow: -7px 7px 27px rgba(0, 7, 3, 0.09);
    font-size: 11px;
    color: #bababa;
    width: 244px;
    padding: 18px 0 18px 96px;
    position: relative;
    background: white;

    &:after {
        content: '';
        position: absolute;
        left: 25px;
        top: 50%;
        transform: translateY(-50%);
    }
}

.notread-themes_btn {
    margin-bottom: 11px;

    &:after {
        width: 37px;
        height: 40px;
        background: url("../img/box-icon.png") no-repeat center;
    }
}

.read-themes_btn {
    &:after {
        width: 45px;
        height: 27px;
        background: url("../img/box-icon-2.png") no-repeat center;
    }
}

.notread-themes_btn.active,
.read-themes_btn.active {
    color: white;
    background: #72AABC;
}

.notread-themes_btn.active {
    &:after {
        background: url("../img/ls-w-1.png") no-repeat center;
    }
}

.read-themes_btn.active {
    &:after {
        background: url("../img/ls-w-2.png") no-repeat center;
    }
}

.forum-themes {
    &_top {
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        align-items: center;

        &__item {
            &:first-child {
                background: #F5F5F5;
                width: 124px;
                padding: 20px 0;
                text-align: center;
                font-size: 14px;
                color: #cbcbcb;
                display: none;
                @media(min-width: 768px) {
                    display: block;
                }
            }

            &:nth-child(2) {
                font-size: 14px;
                color: #cbcbcb;
                padding-left: 48px;
                display: none;
                @media(min-width: 768px) {
                    display: block;
                }
            }

            &:last-child {
                @media(min-width: 768px) {
                    margin-left: auto;
                }
            }
        }
    }
}

.showalltheme-btn {
    width: 243px;
    display: inline-block;
    padding: 20px 0;
    text-align: center;
    text-transform: uppercase;
    color: white;
    font-size: 12px;
}

.forum-article_left {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #EFEFEF;
    padding: 10px 0;
    position: relative;

    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 6px;
        width: 100%;
        background: #579AAF;
        display: none;
    }
    @media(min-width: 768px) {
        width: 124px;
        flex-direction: column;
        padding: 44px 0 21px;
    }
}

.forum-article_right {
    padding: 10px;
    @media(min-width: 768px) {
        width: 80%;
    }
    @media(min-width: 992px) {
        padding: 41px 0 34px 48px;
    }
}

.forum-article {
    @media(min-width: 768px) {
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
    }

    .main-title {
        font-size: 27px;
        text-transform: none;
        margin-bottom: 22px;
        display: inline-block;
    }

    &:hover {
        border: 1px solid #C1DAE1;

        .forum-article_left {
            &:after {
                display: block;
            }
        }
    }
}

.forum-article_date {
    font-size: 14px;
    color: #b5b5b4;
    margin-bottom: 22px;
}

.forum-article_preview {
    font-size: 14px;
}

.forum-article_like {
    font-size: 14px;
    color: #1c1122;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    align-items: center;
    margin: 18px 20px;
    position: relative;
}

.forum-article_likeicon {
    width: 14px;
    height: 12px;
    margin-top: 1px;
    margin-left: 16px;
    transition: 0.5s;
    cursor: pointer;

    &:hover {
        opacity: 0.5;
    }

    &:active {
        opacity: 1;
    }
}

.like-icon_blue {
    background: url("../img/like-blue.png") no-repeat center;
}

.like-icon_red {
    background: url("../img/like-red.png") no-repeat center;
}

.like-icon_grey {
    background: url("../img/like-grey.png") no-repeat center;
}

.forum-article_messcount {
    p {
        display: inline-block;
        font-size: 12px;
        color: #abaaab;
    }
}

.forum-themes_nav {
    @media(min-width: 768px) {
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
    }

    &__left {
        background: #EFEFEF;
        padding: 15px 0;
        @media(min-width: 768px) {
            width: 124px;
        }
    }

    &__right {
        padding: 15px 0 15px 25px;
        @media(min-width: 768px) {
            width: 80%;
        }
        @media(min-width: 992px) {
            padding: 90px 0 70px 25px;
        }
    }
}

.pagination {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    a {
        display: inline-block;
        padding: 6px 8px;
        color: black;
        transition: 0.5s;

        &:hover {
            background: #579AAF;
            color: white;
        }
    }

    .curent {
        background: #579AAF;
        color: white;
    }

    .pagination-left {
        background: url("../img/pag-left.png") no-repeat center;
        margin-right: 19px;

        &:hover {
            background: transparent url("../img/pag-left.png") no-repeat center;
        }
    }

    .pagination-right {
        background: url("../img/pag-right.png") no-repeat center;
        margin: 0 19px;

        &:hover {
            background: transparent url("../img/pag-right.png") no-repeat center;
        }
    }

    .pagination-next {
        font-size: 14px;
        text-transform: uppercase;
        opacity: 0.4;

        &:hover {
            background: none;
            color: #999999;
        }
    }
}

.postcard-info_nav {
    justify-content: center;
    margin: 35px 0 10px;
}

.forum {
    margin-bottom: 12px;
}

.product {
    padding: 10px;
    margin-bottom: 25px;
    @media(min-width: 992px) {
        padding: 0 73px 80px;
    }

    &-item {
        @media(min-width: 1270px) {
            display: -webkit-flex;
            display: -ms-flex;
            display: flex;
            justify-content: space-between;
        }
    }
}

.product-inner {
    max-width: 1122px;
    width: 100%;
    margin: 0 auto;
    padding-top: 25px;
    @media(min-width: 992px) {
        padding-top: 70px;
    }
}

.product-slider {
    .fotorama__stage {
        margin-bottom: 20px;
        box-shadow: -7px 7px 27px rgba(0, 7, 3, 0.09);
    }

    .fotorama__wrap {
        margin: 0 auto;
    }
    @media(min-width: 1270px) {
        width: 50%;
        margin-right: 47px;
    }
}

.product-info {
    @media(min-width: 1270px) {
        width: 50%;
    }
}

.product-title {
    margin-bottom: 25px;
    text-align: center;
    @media(min-width: 1270px) {
        margin-bottom: 80px;
        text-align: left;
    }

    .main-title {
        font-size: 36px;
        text-transform: none;
        margin-bottom: 10px;
    }

    span {
        color: #B4B4B4;
        opacity: 0.5;
    }
}

.product-price {
    margin-bottom: 25px;
    text-align: center;
    @media(min-width: 992px) {
        margin-bottom: 45px;
    }
    @media(min-width: 1270px) {
        text-align: left;
    }

    .product-main_price {
        font-family: 'Cambria';
        color: #5f8fa4;
        font-size: 48px;
    }

    .product-old_price {
        color: #B4B4B4;
        opacity: 0.5;
        display: none;
    }
}

.product-count {
    margin-bottom: 25px;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &_item {
        margin-bottom: 10px;
        @media(min-width: 768px) {
            margin: 0;

            &:first-child {
                margin-right: 10px;
            }
        }
    }
    @media(min-width: 768px) {
        flex-direction: row;
    }
    @media(min-width: 992px) {
        margin-bottom: 39px;
    }
    @media(min-width: 1270px) {
        justify-content: flex-start;
    }
}

.product-preview {
    p {
        margin-bottom: 24px;
    }
}

.product-article {
    .main-title {
        font-size: 24px;
        text-transform: none;
        margin-bottom: 20px;
        @media(min-width: 992px) {
            margin-bottom: 32px;
        }
    }
}

.switch-count {
    width: 100px;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:hover {
        .count-field {
            background: #FD6B7E;
        }
    }

    .switch-button {
        display: inline-block;
        width: 19px;
        height: 19px;
        color: #1c1122;
        text-align: center;
        background: #EAECF0;
        cursor: pointer;
    }

    .count-field {
        width: 41px;
        height: 41px;
        border-radius: 10em;
        background: #579AAF;
        color: white;
        font-size: 24px;
        border: none;
        text-align: center;
    }
}

.action-price {
    .product-main_price {
        color: #fe6b7f;
    }

    .product-old_price {
        display: block;
    }
}

.product-addbasket_btn {
    width: 267px;
    height: 44px;
    text-align: center;
    position: relative;
    color: white;
    text-transform: uppercase;
    font-size: 12px;
    padding-left: 44px;
    border: none;
    cursor: pointer;

    &:after {
        content: '';
        position: absolute;
        left: 40px;
        top: 12px;
        width: 26px;
        height: 22px;
        background: url("../img/cart-w.png") no-repeat center;
    }

    &:hover:after {
        animation: jiggle ease-in-out 400ms infinite;
    }
}

.profile {
    &-ava {
        &_info {
            text-align: center;
            color: white;
            font-size: 14px;
            padding-bottom: 15px;

            li {
                &:not(:last-child) {
                    margin-bottom: 22px;
                }
            }

            .btn {
                font-size: 11px;
            }
        }
    }

    &-social {
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        align-items: center;
        margin: 20px 0;
        @media(min-width: 768px) {
            margin: 0;
        }

        span {
            color: #bdbcbc;
            font-size: 14px;
            display: inline-block;
            margin-right: 27px;
            display: none;
        }

        span.active {
            display: inline-block;
        }

        ul {
            display: -webkit-flex;
            display: -ms-flex;
            display: flex;

            li {
                &:not(:last-child) {
                    margin-right: 9px;
                }
            }
        }
    }

    &-top {
        .cabinet-top_item {
            &:last-child {
                @media(min-width: 1443px) {
                    padding: 40px 30px 0 72px;
                }
            }
        }
    }
}

.profile-social {
    width: 188px;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    justify-content: flex-end;
}

.profile-social_link {
    width: 32px;
    height: 32px;
    background: #DEDEDE;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
        background: #67A3B6;
    }
}

.profile-nick {
    font-family: 'Cambria';
    font-size: 36px;
    color: #fe6b7f;
    margin-bottom: 15px;
}

.profile-vizit {
    color: #b4b4b4;
    margin-bottom: 13px;
    @media(min-width: 992px) {
        margin-bottom: 49px;
    }
}

.profile-name {
    color: #589bb0;
}

.profile-name_inner {
    display: none;
}

.profile-name_hover.hide {
    display: none;
}

.profile-name_inner.active {
    display: block;
}

.profile-article_box {
    height: 100%;
}

.profile-article {
    margin-top: 25px;
    position: relative;
    max-width: 804px;
    width: 100%;
    margin-bottom: 25px;
    @media(min-width: 992px) {
        margin-bottom: 0;
    }

    &_inner {
        height: 56px;
        overflow: hidden;
        transition: 0.5s;
    }

    &_inner.active {
        height: auto;
    }

    .profile-article_show {
        position: absolute;
        right: 0;
        bottom: -20px;
        color: #589bb0;
        cursor: pointer;
    }
    @media(min-width: 992px) {
        margin-top: 43px;
    }
}

.profile-adress {
    background: white;
    padding: 0;

    .main-title {
        padding: 10px 40px 10px 10px;

        &:after {
            top: 15px;
        }
        @media(min-width: 992px) {
            margin: 33px 0 54px 70px;
        }
    }
}

.profile-adress_inner {
    padding: 10px;
    @media(min-width: 1270px) {
        padding: 20px 67px;
    }
}

.map-profile {
    width: 100%;
    height: 464px;
}

.profile-img {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    margin-top: 21px;
    margin-bottom: 30px;

    li {
        margin-right: 10px;
        border: 2px dashed transparent;

        &:hover {
            border: 2px dashed #6E8DA1;
        }

        a {
            img {
                display: block;
            }
        }
    }
}

.return-forum {
    font-family: 'Cambria';
    display: inline-block;
    color: #5f8fa4;
    font-size: 24px;
    position: relative;
    padding-right: 40px;

    &:after {
        content: '';
        position: absolute;
        right: 0;
        top: 7px;
        width: 19px;
        height: 19px;
        background: url("../img/arrow-left.png") no-repeat center;
        opacity: 0.5;
    }
}

.postcard-list {
    max-width: 1132px;
    width: 100%;
    margin: 25px auto;
    text-align: center;
    font-size: 12px;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    @media(min-width: 992px) {
        justify-content: flex-start;
    }

    li {
        max-width: 138px;
        width: 100%;
        height: 256px;
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    &_img {
        margin: 34px 20px;
        cursor: pointer;

        img {
            transition: 0.5s;

            &:hover {
                box-shadow: -7px 7px 27px rgba(0, 7, 3, 0.09);
            }
        }
    }
}

.addpostcard-img {
    display: none;
}

.addpostcard-img_btn {
    cursor: pointer;
}

.delete-postcardimg {
    background: none;
    border: none;
    color: white;
    cursor: pointer;
    opacity: 0.5;
}

.postcard-reg_num {
    p {
        margin-bottom: 20px;
    }
}

.postcard-reg_num__inp {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;

    label {
        display: inline-block;
        border: 2px dashed #8CABB8;

        .inp {
            font-size: 24px;

            &:focus {
                border: none;
            }
        }

        &:first-child {
            max-width: 70px;
            width: 100%;
            position: relative;
            margin-right: 21px;

            &:after {
                content: '';
                position: absolute;
                right: -16px;
                top: 50%;
                transform: translateY(-50%);
                width: 8px;
                height: 2px;
                background: black;
            }
        }

        &:last-child {
            max-width: 320px;
            width: 100%;
        }
    }
}

.postcard-reg {
    .cabinet-top_item {
        &:last-child {
            display: -webkit-flex;
            display: -ms-flex;
            display: flex;
            flex-direction: column;
            align-items: center;
            @media(min-width: 1270px) {
                display: block;
            }
            @media(min-width: 1443px) {
                padding: 40px 90px 0 72px;
            }
        }
    }
}

.postcard-reg_inner {
    &__item {
        &:last-child {
            max-width: 410px;
            width: 100%;
            @media(min-width: 1270px) {
                margin-left: 16px;
                padding-top: 20px;
            }
        }
    }
    @media(min-width: 1270px) {
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
    }
}

.postcard-reg_comment {
    font-family: 'Verdana';
    font-size: 14px;
    width: 100%;
    background: #F8F8F7;
    resize: none;
    margin: 20px 0;
    height: 190px;
    padding: 20px 16px;
    border: none;
}

.postcard-reg_btn {
    text-align: right;
}

.contacts {
    padding: 10px;

    .profile-social {
        margin: 0 auto;
        @media(min-width: 768px) {
            margin: 0;
        }
    }

    &-item {
        &:first-child {
            @media(min-width: 992px) {
                width: 20%;
            }
        }

        &:last-child {
            @media(min-width: 992px) {
                width: 80%;
            }
        }
    }
    @media(min-width: 992px) {
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
    }
    @media(min-width: 1270px) {
        padding: 79px 145px 48px 83px;
    }
}

.contacts-img {
    text-align: center;
    @media(min-width: 768px) {
        text-align: left;
    }
}

.contacts-title {
    font-family: 'Cambria';
    font-size: 36px;
    color: #FF6B80;
    margin-bottom: 38px;
    text-align: center;
    margin-top: 15px;
    @media(min-width: 600px) {
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        justify-content: space-between;
    }
    @media(min-width: 992px) {
        margin-top: 0;
    }
}

.contacts-mess {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    flex-direction: column;
    margin-bottom: 25px;
    @media(min-width: 992px) {
        margin-bottom: 65px;
    }

    a {
        color: #079CB1;
        display: inline-block;
    }
}

.contacts-info {
    @media(min-width: 992px) {
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        justify-content: space-between;
    }

    &_list {
        @media(min-width: 992px) {
            width: 50%;
        }

        li {
            margin-bottom: 24px;

            p {
                font-size: 11px;
                margin-bottom: 8px;
            }
        }

        &:last-child {
            @media(min-width: 992px) {
                padding-top: 21px;
                margin-left: 22px;
            }
        }
    }

    &_field {
        width: 100%;
        font-size: 14px;
        background: #F8F8F7;
        padding: 16px 18px;
    }
}

.contacts-phone {
    font-size: 30px;
}

.contacts-email {
    font-size: 16px;
}

.katalog {
    margin-bottom: 30px;
    background: white url("../img/line-color.png") repeat-x top 10px center;
    padding: 58px 10px;
    @media(min-width: 992px) {
        padding: 58px 76px;
    }

    &-title {
        font-family: 'Cambria';
        font-size: 36px;
        color: #FF6B80;
        margin-bottom: 25px;
        @media(min-width: 992px) {
            margin-bottom: 70px;
        }
    }
}

.pagination-main {
    justify-content: center;
    margin: 25px 0;
}

.katalog-list {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    @media(min-width: 992px) {
        justify-content: flex-start;
        padding: 18px;
    }

    li {
        text-align: center;
        margin-bottom: 25px;
        @media(min-width: 992px) {
            margin-bottom: 50px;
        }
    }

    &_img {
        display: inline-block;
        width: 270px;
        height: 266px;
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        transition: 0.5s;
        margin-bottom: 10px;

        .product-addbasket_btn {
            width: 100%;
            margin-top: 3px;
            opacity: 0;
            transition: 0.5s;
        }

        &:hover {
            box-shadow: -7px 7px 27px rgba(0, 7, 3, 0.09);

            .product-addbasket_btn {
                opacity: 1;
            }
        }
    }

    &_title {
        display: inline-block;
        font-family: 'Cambria';
        font-size: 30px;
        color: #5E8EA3;
        margin-bottom: 12px;
    }

    &_article {
        font-size: 14px;
        color: #BAB7BB;
    }
}

.product-cat_img {
    justify-content: flex-end;
}

.product-cat_count {
    .product-count_item {
        &:last-child {
            text-align: left;
            margin-left: 20px;
        }
    }
}

.product-cat_name {
    margin-bottom: 8px;
    color: black;
    display: inline-block;
}

.product-cat_date {
    color: #BAB7BB;
    margin-bottom: 12px;
}

.service-title {
    color: #5E8EA3;
    @media(min-width: 768px) {
        padding-left: 72px;
    }
}

.product-cat_price_main {
    font-family: 'Cambria';
    color: #5E8EA3;
    font-size: 24px;
    margin-bottom: 7px;
}

.product-cat_price_old {
    color: #BAB7BB;
    display: none;
}

.product-cat_price__action {
    .product-cat_price_main {
        color: #FD6B7E;
    }

    .product-cat_price_old {
        display: block;
    }
}

.product-cat_title {
    margin-bottom: 0;
}

.news-top {
    padding: 65px 10px 25px;
    text-align: center;
    background: white url("../img/line-color.png") repeat-x top 5px center;
    @media(min-width: 768px) {
        text-align: left;
    }
    @media(min-width: 992px) {
        padding: 65px 10px 55px 92px;
    }
}

.news-top_inner {
    .news-top_item {
        &:first-child {
            @media(min-width: 768px) {
                margin-right: 75px;
            }
        }
    }
    @media(min-width: 768px) {
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        align-items: center;
    }
}

.news-top_title {
    font-family: 'Cambria';
    font-size: 36px;
    color: #079CB1;
    margin-bottom: 24px;
}

.news-top_count {
    color: #B5B5B5;
}

.news-list {
    padding: 15px;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 32px;
    padding-bottom: 55px;
    @media(min-width: 768px) {
        flex-direction: row;
        flex-wrap: wrap;
    }

    li {
        max-width: 582px;
        width: 100%;
        margin-bottom: 15px;
        @media(min-width: 768px) {
            display: -webkit-flex;
            display: -ms-flex;
            display: flex;
        }
    }

    &_title {
        font-family: 'Cambria';
        display: inline-block;
        font-size: 24px;
        color: #079CB1;
        margin-bottom: 22px;
    }

    &_date {
        color: #C0BDC0;
        margin-bottom: 20px;
    }
}

.news-bottom {
    margin-bottom: 25px;
    padding-bottom: 50px;
}

.news-list_article {
    @media(min-width: 768px) {
        width: 40%;
        margin-left: 22px;
    }
}

.news-sort {
    padding: 20px;
    @media(min-width: 500px) {
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        align-items: center;
    }
    @media(min-width: 768px) {
        padding: 50px 0 20px 70px;
    }

    p {
        font-family: 'Cambria';
        font-size: 24px;
        color: #079CB1;
        @media(min-width: 500px) {
            margin-right: 30px;
        }
    }
}

.postcard-info_sort {
    margin: 25px 0;
    padding: 22px 20px;
    background: #F4F9FA;
    @media(min-width: 768px) {
        padding: 25px 0 25px 70px;
    }
}

.select-block {
    .ui-button {
        width: auto !important;
        background: none;
        margin: 0;
        border: none;
        padding: 0;
        height: 40px;
        line-height: 40px;
        color: #FD6B7E;
        font-size: 24px;
        font-family: 'Cambria';
        text-transform: none;
        position: relative;

        &:after {
            content: '';
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 11px;
            height: 11px;
            background: url("../img/arrow-down.png") no-repeat center;
        }

        &:focus,
        &:hover {
            background: none;
            outline: none;
            border: none;
        }
    }

    .ui-selectmenu-icon.ui-icon {
        width: 12px;
        height: 6px;
        background: url("../img/select-arrow.png") no-repeat center;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        right: 10px;
    }

    .ui-widget.ui-widget-content {
        padding: 0;
        border: none;
    }

    .ui-menu .ui-menu-item > div {
        padding: 0;
        background: none;
        margin: 0;
        border: none;
        padding: 0;
        height: 40px;
        line-height: 40px;
        text-indent: 10px;
    }

    .ui-widget-content .ui-state-active {
        border: none;
        margin: 0;
    }
}

.inp-label{
    display: block;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    align-items: center;
    input{
        border: none;
        width: 100%;
        background: none;
    }
}

.select-form {
    .ui-button {
        font-family: "Verdana";
        width: 100% !important;
        height: 48px;
        background: #F8F8F7;
        color: #BEBEBE;
        font-size: 14px;
        padding: 3px 20px;

        &:hover, &:focus {
            background: #F8F8F7;
        }

        &:after {
            display: none;
        }
    }
}

.ui-menu li div {
    padding-left: 20px !important;
}

.news-post {
    padding: 10px 10px 25px;
    margin-bottom: 25px;

    p {
        margin-bottom: 33px;
    }
    @media(min-width: 992px) {
        padding: 0 70px 100px;
    }

    &_article {
        max-width: 1140px;
        width: 100%;
        margin: 0 auto;
        padding-top: 25px;
    }

    &_title {
        font-family: 'Cambria';
        font-size: 28px;
        margin-top: 17px;
        margin-bottom: 17px;
        color: #5E8EA3;
        @media(min-width: 992px) {
            font-size: 36px;
            margin-top: 0;
        }
    }

    &_date {
        color: #BAB7BB;
        margin-bottom: 34px;
    }

    &_top {
        margin-bottom: 50px;
        @media(min-width: 992px) {
            display: -webkit-flex;
            display: -ms-flex;
            display: flex;
            justify-content: space-between;
            margin-bottom: 70px;
        }

        &__item {
            @media(min-width: 992px) {
                width: 50%;
            }

            &:first-child {
                @media(min-width: 992px) {
                    margin-right: 47px;
                }
            }

            &:last-child {
                @media(min-width: 1270px) {
                    display: -webkit-flex;
                    display: -ms-flex;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                }
            }
        }
    }
}

.news-post_img__article {
    color: #BAB7BB;
    text-align: right;
    margin-top: 20px;
}

.news-post_img__big {
    max-width: 558px;
    margin: 0 auto;
    @media(min-width: 1270px) {
        height: 520px;
    }

    .news-post_img__inner {
        width: 100%;
        height: 100%;
        padding: 20px;
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        justify-content: center;
        align-items: center;
        @media(min-width: 992px) {
            padding: 60px 20px;
        }
    }
}

.news-post_item {
    margin: 25px 0;
    @media(min-width: 992px) {
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        justify-content: space-between;
    }

    &__inner {
        &:first-child {
            @media(min-width: 768px) {
                display: -webkit-flex;
                display: -ms-flex;
                display: flex;
                justify-content: center;
            }
            @media(min-width: 992px) {
                margin-right: 46px;
            }
        }
    }
}

.news-post_title__inner {
    font-family: 'Cambria';
    font-size: 24px;
    color: #5E8EA3;
    margin-bottom: 33px;
}

.news-post_img {
    width: 272px;
    height: 310px;
    margin: 0 auto;
    @media(min-width: 768px) {
        margin: 0;
    }

    &:first-child {
        @media(min-width: 768px) {
            margin-right: 18px;
        }
    }

    .news-post_img__inner {
        width: 100%;
        height: 83%;
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.basket {
    margin-bottom: 33px;
}

.basket-list {
    .product-cat_img {
        position: relative;
        justify-content: center;

        .delete-basket_product {
            position: absolute;
            top: 10px;
            right: 10px;
            display: none;
        }

        &:hover {
            .delete-basket_product {
                display: block;
            }
        }
    }
}

.basket-top_title {
    font-family: 'Cambria';
    font-size: 28px;
    margin-bottom: 18px;
    color: #FD6B7E;
    @media(min-width: 768px) {
        font-size: 36px;
    }
}

.basket-info_list {
    li {
        margin-bottom: 3px;
        color: #B5B5B5;

        a {
            color: #B5B5B5;
        }

        &:last-child {
            a {
                color: #5E8EA3;
            }
        }
    }
}

.basket-total_box {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-top: 3px dashed #F1F3F7;
    padding: 25px 0;
    @media(min-width: 768px) {
        flex-direction: row;
        align-items: center;
    }
    @media(min-width: 992px) {
        padding: 57px 0;
    }

    &__item {
        text-align: center;

        &:first-child {
            @media(min-width: 768px) {
                margin-right: 84px;
            }
        }

        a {
            display: inline-block;
            color: #089CB1;
            margin: 10px 0;
        }
    }
}

.basket-total {
    font-family: 'Cambria';
    font-size: 24px;
    color: #089CB1;
    margin-bottom: 15px;

    span {
        font-size: 36px;
        display: inline-block;
        margin-left: 30px;
        margin-right: 5px;
    }

    b {
        font-size: 36px;
    }
}

.close_btn {
    width: 17px;
    height: 17px;
    background: url("../img/delete.png") no-repeat center;
    border: none;
    cursor: pointer;
    transition: 0.5s;

    &:hover {
        transform: rotate(90deg);
    }
}

.purchaces-top {
    .basket-top_title {
        color: #079CB1;
    }
}

.purchaces {
    padding-bottom: 30px;
    margin-bottom: 33px;
}

.purchaces-list_img {
    padding: 18px;
    transition: 0.5s;
    margin-bottom: 17px;

    &:hover {
        box-shadow: -7px 7px 27px rgba(0, 7, 3, 0.09);
    }
}

.purchaces-list {
    li {
        text-align: left;
        margin: 10px;
    }
}

.sendpostcard-top {
    padding: 10px;
    @media(min-width: 768px) {
        padding: 22px 0 22px 86px;
    }

    .news-top_inner {
        align-items: flex-start;
    }
}

.sendpostcard-top_img {
    text-align: center;
    padding-top: 30px;
}

.sendpostcard-top_article {
    color: #B5B5B5;
    margin-bottom: 32px;

    br {
        @media(max-width: 767px) {
            display: none;
        }
    }
}

.sendpostcard-top_list {
    .inp {
        font-size: 20px;
        color: black;
    }

    p {
        margin-bottom: 23px;
    }
}

.sendpostcard-user {
    @media(min-width: 992px) {
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
    }

    &_item {
        &:first-child {
            margin-bottom: 20px;
            @media(min-width: 992px) {
                margin-right: 26px;
            }
        }
    }
}

.sendpostcard-user_inner {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;

    label {
        display: inline-block;

        &:first-child {
            width: 72px;
            margin-right: 25px;
            position: relative;

            &:after {
                content: '';
                position: absolute;
                right: -16px;
                top: 50%;
                width: 8px;
                height: 2px;
                background: #151515;
            }
        }
    }
}

.sendpostcard {
    padding: 0;
    @media(min-width: 992px) {
        padding: 53px 0 0;
    }
}

.sendpostcard-data_title {
    font-family: 'Cambria';
    font-size: 24px;
    color: #079CB1;
    max-width: 1255px;
    width: 100%;
    margin: 0 auto 25px;
    padding: 10px;

    p {
        position: relative;
        display: inline-block;
        padding-right: 40px;

        &:after {
            content: '';
            position: absolute;
            right: 0;
            top: 5px;
            width: 19px;
            height: 19px;
            background: url("../img/arrow-down-big.png") no-repeat center;
        }
    }

    a {
        color: #FF6B80;
    }
}

.sendpostcard-form {
    max-width: 1070px;
    width: 100%;
    margin: 0 auto;
    padding: 15px;

    .adress-box_inp {
        width: 100%;
    }

    &_item {
        &:last-child {
            max-width: 450px;
            width: 100%;
            margin: 25px auto;
            @media(min-width: 992px) {
                margin: 0;
            }
        }
    }

    &_title {
        margin-bottom: 24px;

        a {
            color: #FF6B80;
        }
    }

    textarea {
        font-family: 'Verdana';
        font-size: 12px;
        color: #B4B4B4;
        border: none;
        width: 100%;
        height: 273px;
        padding: 19px 15px;
        background: #F8F8F7;
        margin-bottom: 17px;
        resize: none;
    }

    &_index {
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        padding-top: 20px;

        label {
            display: inline-block;
            width: 40px;
            height: 70px;
            position: relative;

            &:after {
                content: '';
                position: absolute;
                top: -14px;
                left: 0;
                width: 100%;
                height: 8px;
                background: #DDDDDD;
            }

            &:not(:last-child) {
                margin-right: 4px;
                @media(min-width: 768px) {
                    margin-right: 20px;
                }
            }

            input {
                font-family: 'Cambria';
                width: 100%;
                height: 100%;
                background: #F2F2F2;
                border: none;
                font-size: 58px;
                color: #079CB1;
                text-align: center;
            }
        }
    }
    @media(min-width: 992px) {
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        justify-content: space-between;
        padding: 35px 25px;
    }
    @media(min-width: 1100px) {
        background: url("../img/bgline.png") no-repeat center;
        background-size: contain;
        padding: 50px 86px;
    }
}

.sendpostcard-form_list {
    li {
        &:last-child {
            margin-top: 0;
        }
    }
}

.sendpostcard-form_label {
    display: block;
    margin-bottom: 10px;
}

.sendpostcard-form_title.grey {
    color: #CAC8CA;
}

.sendbox {
    max-width: 1070px;
    width: 100%;
    margin: 25px auto;
    padding-bottom: 25px;
    text-align: center;
    @media(min-width: 992px) {
        text-align: left;
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
    }
    @media(min-width: 992px) {
        margin: 75px auto 0;
        padding-bottom: 60px;
    }

    &_item {
        margin-bottom: 15px;

        &:first-child {
            @media(min-width: 992px) {
                margin-right: 81px;
                padding-left: 15px;
            }
        }
    }

    &_btn {
        border: none;
        width: 60px;
        height: 48px;
        color: white;
        text-transform: uppercase;
        cursor: pointer;
    }
}

.readytosend {
    display: none;
    margin-top: 45px;
    @media(min-width: 992px) {
        margin-top: 90px;
    }

    &-inner {
        max-width: 1070px;
        width: 100%;
        margin: 0 auto;
        padding-bottom: 40px;
        @media(min-width: 992px) {
            padding-bottom: 127px;
        }
    }
    @media(min-width: 992px) {
        background: url("../img/bg-send.jpg") no-repeat bottom center;
        background-size: contain;
    }

    &-title {
        font-family: 'Cambria';
        font-size: 18px;
        color: #7DA4B4;
        margin-bottom: 25px;
        text-align: center;
        @media(min-width: 992px) {
            font-size: 24px;
            margin-bottom: 58px;
        }
        @media(min-width: 1270px) {
            text-align: left;
        }
    }

    &-time {
        font-family: 'Cambria';
        font-size: 24px;
        color: #7DA4B4;
        text-align: center;
        margin-bottom: 25px;
        @media(min-width: 992px) {
            font-size: 34px;
        }
        @media(min-width: 1270px) {
            font-size: 70px;
        }
    }

    &-article {
        text-align: center;
        margin-bottom: 32px;

        a {
            color: #7DA4B4;
        }
    }

    &-form {
        max-width: 530px;
        width: 100%;
        margin: 0 auto;
        text-align: center;
        position: relative;
        animation: moving 2s infinite linear;

        &_title {
            margin-bottom: 37px;
        }

        &_btn {
            padding: 20px 0;
            color: white;
            border: none;
            font-size: 12px;
            text-transform: uppercase;
            text-align: center;
            width: 100px;
            cursor: pointer;
            @media(min-width: 768px) {
                width: 220px;
            }
        }
    }
}

.readytosend-form_item {
    padding: 25px 0;
    @media(min-width: 992px) {
        padding: 45px 0 38px;
    }
}

.readytosend-form_result {
    position: relative;
    padding: 25px 0;
    display: none;
    @media(min-width: 992px) {
        padding: 45px 0 38px;
    }

    .close-form_result {
        position: absolute;
        right: 10px;
        top: 10px;
        width: 17px;
        height: 17px;
        background: url("../img/delete.png") no-repeat center;
        border: none;
        cursor: pointer;
        transition: 0.5s;

        &:hover {
            transform: rotate(90deg);
        }
    }

    p {
        font-family: 'Cambria';
        color: #7DA4B4;
        font-size: 28px;
    }
}

.adress-box_room {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    max-width: 440px;
    width: 100%;
    padding-right: 25px;

    &__item {
        &:first-child {
            width: 33%;
            margin-right: 9px;
        }

        &:last-child {
            width: 67%;
        }
    }
}

.editadress {
    max-width: 1070px;
    width: 100%;
    margin: 0 auto;
    padding: 35px 10px 55px;
    display: none;

    .editadress-inner {
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        flex-direction: column;
        align-items: center;
        @media(min-width: 768px) {
            flex-direction: row;
            align-items: baseline;
        }
    }

    .adress-box_inp {
        width: 100%;
    }
    @media(min-width: 992px) {
        padding: 72px 0 114px;
    }

    &-title {
        margin-bottom: 25px;
    }

    &-item {
        &:first-child {
            @media(min-width: 768px) {
                width: 30%;
            }
        }

        &:last-child {
            @media(min-width: 768px) {
                width: 70%;
            }
        }
    }
}

.editadress-btn_wrap {
    max-width: 440px;
    width: 100%;
    text-align: center;
    margin-top: 25px;
    @media(min-width: 992px) {
        margin-top: 40px;
    }
}

.hide-block {
    display: none;
}

.show-block {
    display: block;
}

.foruminner-head {
    padding: 25px 0;
    text-align: center;
    @media(min-width: 768px) {
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 35px 58px;
    }
    @media(min-width: 992px) {
        width: 753px;
    }

    &_title {
        font-size: 36px;
        font-family: 'Cambria';
        color: #3D90A5;
        margin-bottom: 25px;
        @media(min-width: 768px) {
            margin-bottom: 0;
        }
    }

    &_link {
        display: inline-block;
        padding: 17px 13px;
        background: #F8F8F7;
        border: 2px dashed #5E8EA3;
        color: black;
    }
}

.foruminner {
    padding-bottom: 25px;
    @media(min-width: 992px) {
        padding-bottom: 70px;
    }

    &-top {
        margin-bottom: 25px;
        @media(min-width: 992px) {
            margin-bottom: 40px;
        }
    }

    &-title {
        background: #39A6B8;
        padding: 15px 0;
        text-align: center;
        @media(min-width: 768px) {
            padding: 24px 58px;
            display: -webkit-flex;
            display: -ms-flex;
            display: flex;
            align-items: center;
        }

        p {
            font-family: 'Cambria';
            color: white;
            font-size: 30px;
            @media(min-width: 768px) {
                margin-right: 30px;
            }
        }

        .toggle-article {
            color: #B5D7DF;
            background: none;
            border: none;
            cursor: pointer;
            @media(min-width: 768px) {
                padding-top: 7px;
            }
        }
    }
}

.foruminner-article {
    padding: 23px 10px 19px;
    @media(min-width: 768px) {
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        padding: 23px 10px 19px 34px;
    }

    &_item {
        &:last-child {
            max-width: 1112px;
            width: 100%;
            @media(min-width: 768px) {
                margin-left: 40px;
            }
        }
    }
}

.foruminner-nick {
    color: black;
}

.foruminner-article_top {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    justify-content: center;
    margin: 22px 0;
    @media(min-width: 768px) {
        margin: 0 0 22px;
        justify-content: flex-start;
    }

    .foruminner-nick {
        margin-right: 18px;
        color: black;
    }
}

.foruminner-like {
    margin: 0 0 20px;
    color: #B5D7DF;
    @media(min-width: 570px) {
        margin: 0;
        padding-left: 26px;
    }
}

.foruminner-top_bottom {
    margin-top: 34px;
}

.answear_author {
    color: #089CB1;
    font-size: 16px;
}

.my-answear_color {
    color: #FD6B7E;
}

.auth-user_img {
    transition: 0.5s;

    &:hover {
        border: 4px solid #FD6B7E;
    }
}

.quote-text {
    padding: 15px 10px;
    font-size: 12px;
    border: 1px solid #E5E5E4;
    margin-bottom: 15px;

    a {
        color: #089CB1;
    }

    &_author {
        color: #089CB1;
        font-size: 13px;
        padding-bottom: 10px;
    }
}

.foruminner-date {
    color: #B5D7DF;

    span {
        display: inline-block;
        margin-left: 25px;
    }
}

.foruminner-imgbox {
    text-align: center;
}

.foruminner-img {
    width: 66px;
    height: 66px;
    display: inline-block;
    border-radius: 10em;
    background: #EFEFEF;
    margin-bottom: 15px;
}

.foruminner-city {
    color: #BABAB9;
}

.foruminner-top_comment {
    border-bottom: 1px solid #DDDDDD;
    background: #EEEEEE;
    padding: 19px 10px;
    @media(min-width: 768px) {
        padding: 19px 10px 19px 140px;
    }

    textarea {
        font-family: 'Verdana';
        font-size: 14px;
        width: 100%;
        height: 45px;
        border: none;
        background: white;
        color: black;
        padding: 13px 10px;
        resize: none;
        @media(min-width: 768px) {
            padding: 13px 23px;
        }
    }

    &__item {
        max-width: 1112px;
        width: 100%;
        @media(min-width: 768px) {
            display: -webkit-flex;
            display: -ms-flex;
            display: flex;
            align-items: center;

            label {
                max-width: 900px;
                width: 100%;
            }
        }
    }
}

.comment-btn {
    width: 210px;
    height: 45px;
    color: white;
    background: #089CB1;
    text-transform: uppercase;
    text-align: center;
    border: none;
    margin: 25px 0;
    cursor: pointer;
    @media(min-width: 768px) {
        margin: 0 0 0 12px;
    }
}

.foruminner-bottom {
    max-width: 1112px;
    width: 100%;
    margin: 0 auto;

    &_inner {
        &:hover {
            .foruminner-bottom_btn {
                opacity: 1;
            }

            .foruminner-commentcount {
                color: #089CB1;
            }
        }
        @media(min-width: 992px) {
            display: -webkit-flex;
            display: -ms-flex;
            display: flex;
            justify-content: space-between;
        }
    }

    &_item {
        margin-bottom: 25px;
    }

    &_article {
        .foruminner-date {
            margin-bottom: 14px;
        }
    }

    &_comment {
        background: #F8F8F7;
    }

    &_text {
        max-width: 888px;
        width: 100%;
        padding: 10px;
        @media(min-width: 768px) {
            padding: 20px 26px;
        }
    }

    &_action {
        padding: 10px 10px 0;
        @media(min-width: 570px) {
            padding: 0;
            display: -webkit-flex;
            display: -ms-flex;
            display: flex;
            align-items: center;
        }
    }

    &_btn {
        transition: 0.5s;
        @media(min-width: 992px) {
            opacity: 0;
        }
        @media(min-width: 570px) {
            margin-left: auto;
        }
    }
}

.foruminner-messcount {
    text-align: center;
    font-size: 12px;
    color: #B6B6B5;
    margin-top: 5px;
}

.quote-btn {
    position: relative;
    background: none;
    border: none;
    text-transform: uppercase;
    padding-right: 32px;
    color: #D3D3D2;
    cursor: pointer;

    &:after {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        width: 19px;
        height: 19px;
        background: url("../img/arrow-down.png") no-repeat center;
        opacity: 0.5;
    }
}

.foruminner-bottom_date {
    text-align: center;

    span {
        display: inline-block;
        margin-left: 34px;
    }
    @media(min-width: 992px) {
        text-align: left;
    }
}

.foruminner-commentcount {
    color: #B6B6B5;
    margin-bottom: 20px;
    cursor: pointer;
    @media(min-width: 570px) {
        margin-left: 10px;
        margin-bottom: 0;
    }
    @media(min-width: 768px) {
        margin-left: 55px;
    }
}

.foruminner-bottom_imgbox {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    justify-content: center;
    margin-bottom: 25px;

    &__item {
        &:first-child {
            margin-right: 25px;
        }

        &:last-child {
            padding-top: 12px;
        }
    }
    @media(min-width: 992px) {
        justify-content: flex-start;
        margin-right: 56px;
        margin-bottom: 0;
        padding-top: 30px;
        padding-left: 10px;
    }
    @media(min-width: 1270px) {
        padding-left: 0;
    }

    .foruminner-img {
        margin-bottom: 0;
    }

    .foruminner-nick {
        display: inline-block;
        margin-bottom: 10px;
    }
}

.foruminner-bottom_comments {
    max-width: 900px;
    width: 100%;
    margin: 25px 0 25px auto;
    display: none;

    .foruminner-bottom_inner {
        margin-bottom: 20px;
        @media(min-width: 768px) {
            display: -webkit-flex;
            display: -ms-flex;
            display: flex;
        }
    }

    .foruminner-bottom_imgbox {
        @media(min-width: 768px) {
            padding-top: 30px;
            padding-left: 10px;
            margin: 0 52px 0 0;
        }
    }

    .foruminner-date {
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        justify-content: space-between;
        padding: 0 10px;
        @media(min-width: 992px) {
            padding: 0;
        }

        b {
            color: black;
        }

        a {
            color: #38A5B8;
        }
    }
}

.foruminner-bottom_comment.active {
    background: #E5F1F4;
    border-bottom: 4px solid #38A5B8;
}

.comment-answears {
    border: 1px solid transparent;
}

.comment-answears.active {
    border: 1px solid #38A5B8;
    background: #F8F8F7;
}

.quote-box {
    p {
        padding: 10px 0;
        font-size: 12px;
    }
}

.foruminner-formcomment {
    border: 1px solid transparent;
    margin-top: 25px;
    margin-bottom: 25px;

    &_title {
        background: #E5F1F4;
        border-top: 4px solid #38A5B8;
        padding: 15px 0 15px 25px;

        a {
            color: #38A5B8;
        }
    }

    &_textarea {
        font-family: "Verdana";
        font-size: 14px;
        width: 100%;
        padding: 7px 10px 10px 24px;
        resize: none;
        height: 65px;
        margin-bottom: 25px;
        background: #F8F8F7;
        border: none;
        @media(min-width: 992px) {
            margin-bottom: 40px;
        }
    }

    &_inner {
        padding: 10px 10px 0;
        @media(min-width: 768px) {
            padding: 10px 24px 0;
        }
    }

    &_btnbox {
        text-align: right;
    }
}

.foruminner-formcomment.active {
    border: 1px solid #38A5B8;
}

.pagination-forum {
    margin-top: 45px;
}

.tooltip-wrap {
    position: relative;
}

.advantage {
    max-width: 1178px;
    width: 100%;
    margin: 0 auto;
    position: relative;
    padding: 30px 10px;
    animation: moving 2s infinite linear;

    .advantage-inner {
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        flex-direction: column;
        align-items: center;
        @media(min-width: 768px) {
            flex-direction: row;
            justify-content: space-between;
        }
    }

    .advantage-line {
        position: absolute;
        width: 100%;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        display: none;
        @media(min-width: 1270px) {
            display: block;
        }
    }

    &-item {
        @media(min-width: 768px) {
            &:nth-child(2) {
                display: -webkit-flex;
                display: -ms-flex;
                display: flex;
                align-items: center;

                .advantage-img {
                    margin-right: 20px;
                }
            }

            &:not(:nth-child(2)) {
                display: -webkit-flex;
                display: -ms-flex;
                display: flex;
                flex-direction: column;
                align-items: center;
            }
        }
    }

    &-img {
        transition: 0.5s;
        transform: scale(0.5);
    }

    &-counter {
        font-size: 28px;
        font-family: "Cambria";
        color: #FF7A8B;
        margin-bottom: 13px;
        transition: 0.5s;
    }

    &-article {
        color: #5F8FA3;
        font-family: "Verdana";
        font-size: 14px;
    }
    @media(min-width: 1270px) {
        height: 680px;
        padding: 0;

        &-item {
            position: relative;

            &:after {
                content: '';
                position: absolute;
                width: 21px;
                height: 21px;
                background: #6F9AAC;
                border-radius: 10em;
                transition: 0.5s;
                transform: scale(0);
            }

            &:hover {
                &:after {
                    transform: scale(1);
                }

                .advantage-counter,
                .advantage-img {
                    transform: scale(1.1);
                }
            }
        }

        .advantage-first {
            position: absolute;
            left: 140px;
            top: 159px;

            .advantage-img {
                margin-bottom: 45px;
            }

            &:after {
                top: 48%;
            }
        }

        .advantage-next {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: 130px;

            &:after {
                top: -26px;
                left: 43%;
            }
        }

        .advantage-last {
            position: absolute;
            right: 120px;
            top: 135px;

            .advantage-img {
                margin-bottom: 30px;
            }

            &:after {
                top: 54%;
            }
        }
    }
}

.pagination-center {
    justify-content: center;
}

.order {
    padding: 50px 0;
    margin-bottom: 25px;

    &-item {
        position: relative;

        .close_btn {
            position: absolute;
            right: 5px;
            top: -40px;
            @media(min-width: 992px) {
                right: 50px;
            }
        }
    }
    @media(min-width: 1270px) {
        padding: 100px 0;
    }

    &-top {
        text-align: center;
        margin-bottom: 30px;
        padding: 0 10px;
        @media(min-width: 992px) {
            margin-bottom: 45px;
        }
    }

    &-article,
    &-title {
        font-family: 'Cambria';
        margin-bottom: 25px;
    }

    &-title {
        color: #5F8FA3;
        font-size: 36px;
    }

    &-article {
        color: #D4D4D4;
        font-size: 20px;
        @media(min-width: 768px) {
            font-size: 24px;
        }
    }
}

.order-article_link {
    transition: 0.5s;

    &:hover {
        color: #5F8FA3;
    }
}

.order-radio_inp {
    display: none;
}

.order-personal_radio {
    margin-bottom: 25px;
    @media(min-width: 450px) {
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        justify-content: space-between;
    }

    li {
        margin-bottom: 15px;

        &:first-child {
            .order-radio_label {
                &:after {
                    top: -1px;
                }

                &:before {
                    top: 6px;
                }
            }
        }

        &:first-child {
            &:before {
                top: 3px;
            }
        }
    }
}

.order-radio_inp+.order-radio_label {
    display: inline-block;
    padding-left: 37px;
    position: relative;
    font-size: 12px;
    cursor: pointer;

    &:after {
        content: '';
        position: absolute;
        left: 0;
        top: -3px;
        width: 20px;
        height: 20px;
        border-radius: 10em;
        background: #EAEDF1;
        z-index: 0;
    }

    &:before {
        content: '';
        position: absolute;
        left: 6px;
        top: 4px;
        width: 7px;
        height: 7px;
        border-radius: 10em;
        background: #FFFFFF;
        display: none;
        z-index: 10;
    }
}

.order-radio_inp:checked + .order-radio_label {
    &:after {
        background: #FF7A8B;
    }

    &:before {
        display: block;
    }
}

.order-table_wrap {
    overflow-x: auto;
}

.order-table {
    width: 658px;
    border-collapse: collapse;
    border-spacing: 0;

    th {
        vertical-align: middle;
    }

    tr {
        background: #F8F8F7;
        transition: 0.5s;

        &:hover {
            background: #F2F2F0;
        }

        &:nth-child(1) {
            background: #5E8EA3;
        }

        &:not(:first-child) {
            th {
                padding: 8px;

                &:last-child {
                    width: 200px;
                }
            }
        }
    }

    &_title {
        color: white;
        font-size: 12px;

        th {
            padding: 20px 24px;

            &:first-child {
                text-align: left;
            }
        }
    }
}

.order-table_img {
    width: 55px;
    height: 55px;
    margin-right: 25px;
    padding: 10px 8px;
    background: white;
    cursor: pointer;
}

.order-table_item {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    align-items: center;
}

.order-table_price {
    position: relative;
}

.order-table_delete {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 30px;
    width: 10px;
    height: 10px;
    background: url("../img/delete.png") no-repeat center;
    background-size: contain;
    cursor: pointer;
}

.order-table_bottom {
    background: #F8F8F7;
    padding: 27px 0 10px;
    text-align: left;
    padding-left: 10px;
    @media(min-width: 768px) {
        text-align: center;
        padding-left: 0;
    }
}

.order-table_btn {
    font-size: 12px;
    color: #A9A9A9;
    background: #F2F2F0;
    border-radius: 15px;
    text-align: center;
    padding: 10px 22px;
    border: none;
    cursor: pointer;
    transition: 0.5s;

    &:hover {
        background: #E0E5E7;
        color: black;
    }
}

.order-total {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 25px;
}

.order-total_price {
    font-family: 'Cambria';
    font-size: 24px;
    color: #5F8FA3;
}

.order-btn_wrap {
    text-align: center;
    margin: 0 auto;
    @media(min-width: 992px) {
        margin-top: 60px;
    }

    p {
        font-size: 12px;
        margin-bottom: 18px;
    }

    .order-btn {
        width: 218px;
        padding: 18px 0;
    }
}

.order-personal_radio {
    max-width: 416px;
    width: 100%;
}

.order-btn_wrap,
.order-total {
    max-width: 658px;
    width: 100%;
}

.order-table_bottom {
    width: 658px;
}

.order-inner {
    padding: 10px;
    @media(min-width: 768px) {
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    @media(min-width: 1270px) {
        flex-direction: row;
        justify-content: center;
    }

    &_item {
        &:first-child {
            margin-bottom: 25px;
        }
        @media(min-width: 1270px) {
            &:first-child {
                margin-right: 30px;
                margin-bottom: 0;
            }
        }
    }

    .adress-box_inp {
        width: 100%;
    }
}

.order-link {
    font-size: 12px;
    color: #5F8FA3;
}

.pt {
    margin-top: 33px;

    span {
        color: #C8C8C8;
        display: inline-block;
        padding-top: 10px;
    }
}

.order-shipping_toggle {
    display: none;
}

.order-link.order-toggle {
    margin-top: 5px;
    cursor: pointer;
}

.order-payinfo_inner {
    .order-inner_item {
        &:first-child {
            @media(min-width: 1446px) {
                width: 500px;
                margin-right: 0;
            }
        }
    }
}

.order-shipping_about {
    margin-bottom: 25px;
    @media(min-width: 992px) {
        margin-bottom: 50px;
    }
}

.order-shipping {
    padding-bottom: 25px;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;

    &_item {
        &:first-child {
            margin-right: 26px;
        }
    }
}

.order-inner_title {
    font-family: 'Cambria';
    color: #5F8FA3;
    font-size: 24px;
    margin-bottom: 30px;
}

.service {
    padding: 50px 10px;
}

.service-list {
    .product-count {
        flex-direction: row;
    }
    @media(min-width: 768px) {
        justify-content: space-around;
    }

    li {
        width: 270px;

        .service-list_item {
            &:first-child {
                @media(min-width: 768px) {
                    margin-right: 20px;
                }
            }
        }
        @media(min-width: 1270px) {
            width: 560px;
            display: -webkit-flex;
            display: -ms-flex;
            display: flex;
        }
    }

    &_title {
        font-family: 'Cambria';
        font-size: 18px;
        color: #5E8EA3;
        font-size: 22px;
        margin-bottom: 15px;
    }
}

.service-list_info {
    text-align: left;
    margin-bottom: 25px;
}

.user-comment_padding {
    padding-bottom: 25px;
    padding-right: 20px;
}

.user-comment_bg {
    background: #EDF5F9;
}
